import { Autocomplete, ListItem, ListItemText, createFilterOptions } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import debounce from "lodash/debounce";
import { Component } from "react";
import { AppContext } from "../../common/AppContext";
import { ReactComponent as DropIcon } from "../../resources/images/icons-chevron-double.svg";
import { ReactComponent as RemoveIcon } from "../../resources/images/icons-remove.svg";
import { TextField } from "../TextField";

export class AbstractFilterSelect extends Component<any, any> {
  static contextType = AppContext;

  static defaultProps = {
    listType: "Filter",
    maxResults: 50,
    initialSearch: false,
    multiple: true,
    clearable: true,
    hasNoneItem: false,
    noneItemLabel: "None",
    hasAllItem: false,
    hasNewItem: false,
    allItemLabel: "All",
    getItemId: null,
    debounce: true,
    clearOnSelection: false,
    clearResultsOnSelection: false,
    noOptionsText: "No search results",
    excludeItems: [],
    matchFrom: "any" //start or any
  };

  initialItems: any;
  value: any;
  hasTyped: boolean;
  filterOptions: any;
  debounceLoadItems: any;
  ignorePendingResults: boolean;
  dataList: any;

  constructor(props: any) {
    super(props);

    const allItems = this.filterItems(props.items || props.initialItems || []);

    this.state = {
      selectedValues: props.value ? this.findValue(allItems, props.value) : null,
      allValues: allItems,
      valid: null,
      forceBlur: false
    };

    this.initialItems = props.initialItems;
    this.value = props.value;
    this.hasTyped = false;

    this.filterOptions = createFilterOptions({
      matchFrom: props.matchFrom,
      limit: props.maxResults,
      ignoreCase: true,
      stringify: (option) => this.getItemLabel(option)
    });

    this.debounceLoadItems = debounce((s) => this.loadItems(s), 500);
    this.ignorePendingResults = false;
  }

  onChange(e: any, selected: any) {
    const { onChange, clearOnSelection, clearResultsOnSelection, validationMethod } = this.props;
    const { valid } = this.state;

    if (validationMethod) {
      const newValid = validationMethod(e, e.target.value);
      if (newValid !== valid) this.setState({ valid: newValid });
    }

    if (clearOnSelection)
      this.setState(
        {
          selectedValues: null,
          forceBlur: true,
          ...(clearResultsOnSelection && { allValues: null })
        },
        () => {
          onChange(e, selected);
        }
      );
    else
      this.setState({ selectedValues: selected }, () => {
        onChange(e, selected);
      });
  }

  getItemLabel(item: any) {
    const {
      getItemLabel,
      hasNoneItem,
      noneItemLabel,
      hasAllItem,
      allItemLabel,
      hasNewItem,
      newItemLabel
    } = this.props;
    if (getItemLabel) return getItemLabel(item);

    if (hasNoneItem && item.isNoneItem === true) return noneItemLabel;
    else if (hasAllItem && item.isAllItem === true) return allItemLabel;
    else if (hasNewItem && item.isNewItem === true) return newItemLabel;
    return item.label;
  }

  getItemSecondaryLabel(item: any) {}

  getItemState(item: any) {
    const { getItemState } = this.props;
    return getItemState ? getItemState(item) : null;
  }

  getItemSecondaryAction(item: any) {}

  getList(dataList: any) {}

  compareItems(a: any, b: any) {
    const { compareItems } = this.props;
    if (compareItems) return compareItems(a, b);
    return a === b;
  }

  onInputChange(e: any, value: any, reason: any) {
    const { debounce, items, onInputChange } = this.props;

    if (value === null) {
      this.value = null;
      if (onInputChange) onInputChange("");
    } else if (value === "" || reason === "reset") {
      this.clearResults();
      if (onInputChange) onInputChange("");
      return;
    }
    if (e && reason === "input") {
      this.hasTyped = true;
      this.ignorePendingResults = false;
      if (onInputChange) onInputChange(value);
      if (debounce) this.debounceLoadItems(value);
      else this.loadItems(value);
    }
  }

  clearResults() {
    const { items } = this.props;
    this.ignorePendingResults = true;
    this.value = "";
    this.setItems(this.initialItems ? this.initialItems : items ? items : []);
  }

  onBlur(e: any) {
    const { onBlur, validationMethod } = this.props;
    const { valid, forceBlur } = this.state;

    if (validationMethod) {
      const newValid = validationMethod(e, e.target.value);
      if (newValid !== valid) this.setState({ valid: newValid });
    }

    if (forceBlur) this.setState({ forceBlur: false });

    if (onBlur) onBlur(e);
  }

  componentDidMount() {
    if (this.props.initialSearch) this.loadItems(null, true);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { allValues } = this.state;

    if (prevProps.initialItems !== this.props.initialItems) {
      this.initialItems = this.props.initialItems;
      this.setItems(this.props.initialItems);
    }
    if (prevProps.items !== this.props.items) {
      this.setItems(this.props.items);
    }
    if (prevProps.value !== this.props.value) {
      this.value = this.props.value;
      this.setState({ selectedValues: this.findValue(allValues, this.value) });
    }
  }

  findValue(items: any, value: any) {
    if (value && typeof value === "object") return value;
    return this.findValueItem(items, value);
  }

  findValueItem(items: any, value: any) {
    return null;
  }

  setItems(allValues: any) {
    const filteredItems = this.filterItems(allValues);
    this.setState({
      allValues: filteredItems,
      selectedValues: this.findValue(filteredItems, this.props.value)
    });
  }

  filterItems(allValues: any) {
    const { filterItems } = this.props;
    if (filterItems) return filterItems(allValues);
    return allValues;
  }

  loadItems(value: any, initialSearch?: any) {
    const { callApi, listType } = this.props;

    if (callApi === false && initialSearch !== true) return;

    this.value = value;
    if (initialSearch !== true && (!value || value.length === 0)) return;

    this.context.setLoading("search" + listType, true);

    this.getData(value)
      .then((list: any) => {
        if (this.ignorePendingResults) this.ignorePendingResults = false;
        else {
          const values = this.getList(list);
          //if (!this.initialItems) this.initialItems = values;
          this.setItems(values);
        }
      })
      .catch((error: any) => {
        //Todo: handle error
        //alert('error 4545365: ' + error);
      })
      .finally(() => this.context.setLoading("search" + listType, false));
  }

  getData(value: any) {
    const { getData } = this.props;
    const customGetData = getData ? getData(value) : null;
    return customGetData ? customGetData : this.dataList.get(this.context, value);
  }

  filter(options: any, inputValue?: any) {
    const { maxResults, multiple } = this.props;
    const { allValues, selectedValues } = this.state;

    /*if (selectedValues && this.hasTyped) {
            if(multiple) return options.filter(o => selectedValues.find(s => this.compareItems(s, o)) === undefined).slice(0, maxResults);
            else return options.filter(o => this.compareItems(selectedValues, o)).slice(0, maxResults);
        }
        else */ return options.slice(0, maxResults);
  }

  renderOption(props: any, option: any, state: any) {
    const { getItemId } = this.props;

    const id = getItemId ? getItemId(option) : option.id;
    const name = this.getItemLabel(option);
    const itemState = this.getItemState(option);
    const secondaryLabel: any = this.getItemSecondaryLabel(option);
    const secondaryAction: any = this.getItemSecondaryAction(option);
    const matches = match(name, state.inputValue);
    const parts = parse(name, matches);

    return (
      <ListItem
        {...props}
        className={
          "klayo-filterselector_option" +
          (itemState ? " klayo-filterselector_option--" + itemState : "")
        }
        key={id}
        secondaryAction={secondaryAction ? secondaryAction : null}
      >
        <ListItemText
          className='klayo-filterselector_option_text'
          primary={
            parts.length === 1 ? (
              name
            ) : (
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    className={
                      "klayo-selector_itempart" +
                      (part.highlight === true ? " klayo-selector_highlight" : "")
                    }
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            )
          }
          secondary={secondaryLabel ? secondaryLabel : null}
        />
      </ListItem>
    );
  }

  isOptionEqualToValue(a: any, b: any) {
    if (a === null || b === null) return 0;

    const { getItemId } = this.props;
    if (getItemId === null) return a === b;

    return getItemId(a) === getItemId(b);
  }

  getItemId(item: any) {
    const { getItemId } = this.props;
    return getItemId ? getItemId(item) : item;
  }

  render() {
    const {
      label,
      sx,
      placeholder,
      focusPlaceholder,
      multiple,
      clearable,
      validationText,
      helperText,
      disabled,
      loading,
      hasNoneItem,
      noneItemLabel,
      hasAllItem,
      allItemLabel,
      hasNewItem,
      newItemLabel,
      getItemId,
      noOptionsText,
      disabledSearch,
      filterOptionsProps
    } = this.props;
    const { allValues, selectedValues, valid, forceBlur } = this.state;

    const options = [];
    if (hasNoneItem) options.push({ id: -10, name: noneItemLabel, isNoneItem: true });
    if (hasAllItem) options.push({ id: -11, name: allItemLabel, isAllItem: true });
    if (hasNewItem) options.push({ id: -12, name: "", isNewItem: true });

    if (allValues && allValues.length !== 0) options.push(...allValues);
    else if (this.initialItems) options.push(...this.initialItems);

    return (
      <Autocomplete
        className='klayo-filterselector klayo-selector'
        options={this.filter(options)}
        value={selectedValues ? selectedValues : null}
        getOptionLabel={this.getItemLabel.bind(this)}
        //filterOptions={this.filter.bind(this)}
        filterOptions={filterOptionsProps || this.filterOptions}
        isOptionEqualToValue={this.isOptionEqualToValue.bind(this) as any}
        autoComplete
        includeInputInList
        disableClearable={clearable === false}
        filterSelectedOptions
        popupIcon={<DropIcon />}
        clearIcon={<RemoveIcon />}
        onBlur={this.onBlur.bind(this)}
        handleHomeEndKeys
        noOptionsText={noOptionsText}
        ChipProps={{
          deleteIcon: <RemoveIcon />
        }}
        multiple={multiple}
        renderOption={this.renderOption.bind(this)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            disabled={disabled}
            loading={loading}
            placeholder={placeholder || "Search"}
            focusPlaceholder={focusPlaceholder}
            error={valid === false}
            helperText={valid === false ? validationText : helperText}
            forceBlur={forceBlur}
            sx={{ width: "100%" }}
            endAdornment={params.InputProps.endAdornment}
          />
        )}
        onChange={this.onChange.bind(this)}
        onInputChange={this.onInputChange.bind(this)}
        sx={sx}
        onKeyDown={(e) => {
          disabledSearch && e.preventDefault();
        }}
      />
    );
  }
}
