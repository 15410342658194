import { Stack } from "@mui/material";
import { Alert } from "klayowebshared";
import { cloneDeep, isEqual } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Dialog } from "../../components/dialogs/Dialog";
import { SelectMenu } from "../../components/SelectMenu";
import { MultiAttributeSelector } from "../../components/selectors/MultiAttributeSelector";
import { TextField } from "../../components/TextField";
import { COURSE_RECURRENCE, CREATE_TRAINING_MODE } from "../../constants";
import { ITeamTrainingDataType } from "../../interfaces/Training";

const CreateTrainingDialog = (props: any) => {
  const {
    mode,
    onClose,
    onSave,
    theme,
    error,
    newTrainingName,
    trainingData,
    organizationIntegrationId,
    isLoading,
    manualIntegrations,
    onShowNewAttributeDialog,
    onLoadAttributes,
    attributesClass
  } = props;

  const [trainingName, setTrainingName] = useState(newTrainingName || "");
  const [requiredFaculties, setRequiredFaculties] = useState("");
  const [recurrence, setRecurrence] = useState<
    | {
        value: number;
      }
    | undefined
  >();
  const [selectedCompetencies, setSelectedCompetencies] = useState([]);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(
    organizationIntegrationId || ""
  );
  const [hasEdit, setHasEdit] = useState(false);

  const [existingTrainingData, setExistingTrainingData] = useState<
    ITeamTrainingDataType | undefined
  >(undefined);

  const attributes = useMemo(() => {
    if (!attributesClass) return [];

    return attributesClass.attributes;
  }, [attributesClass]);

  const integrationItems = useMemo(() => {
    if (!manualIntegrations || !Array.isArray(manualIntegrations)) {
      return [];
    }

    const items = manualIntegrations.map((integration: any) => [
      integration.id,
      {
        value: integration.id,
        label: integration.name || `Integration ${integration.id}`
      }
    ]);
    return items;
  }, [manualIntegrations]);

  // Function to find and set the integration
  const findAndSetIntegration = (organizationIntegrationName: string) => {
    const integrationItem = integrationItems.find(
      (item: any) => item[1].label === organizationIntegrationName
    );

    if (integrationItem) setSelectedIntegrationId(integrationItem[1]);
  };

  // Function to set required faculties
  const setFacultiesFromTrainingData = (faculties: any) => {
    setRequiredFaculties(faculties);
  };

  // Function to find and set recurrence
  const findAndSetRecurrence = (trainingRecurrence: number) => {
    const recurrenceValue = Object.entries(COURSE_RECURRENCE).find(
      ([key, value]) => value.value === trainingRecurrence
    );

    setRecurrence(recurrenceValue ? recurrenceValue[1] : { value: COURSE_RECURRENCE.NONE.value });
  };

  // Function to set competencies
  const setCompetenciesFromTrainingData = (associatedAttributes: any) => {
    if (associatedAttributes && associatedAttributes.length > 0) {
      const competenciesData = associatedAttributes?.map((attr: any) => {
        const foundCompetency = attributes?.find((a: any) => a.id === attr.attributeDefinitionId);
        return foundCompetency;
      });

      setSelectedCompetencies(competenciesData);
    } else {
      setSelectedCompetencies([]);
    }
  };

  useEffect(() => {
    if (mode === CREATE_TRAINING_MODE.EDIT && trainingData && attributes && attributes.length > 0) {
      // Set training name
      setTrainingName(trainingData.name || "");

      // Find and set integration
      findAndSetIntegration(trainingData.organizationIntegrationName);

      // Set faculties
      setFacultiesFromTrainingData(trainingData.facultiesAsString);

      // Find and set recurrence
      findAndSetRecurrence(trainingData.recurrence);

      // Set competencies
      setCompetenciesFromTrainingData(trainingData.associatedAttribute);

      // Clone the training data for comparison
      const clonedData: ITeamTrainingDataType = {
        name: trainingData.name || "",
        faculties: trainingData.facultiesAsString,
        recurrence: trainingData.recurrence,
        competencies: trainingData.associatedAttribute
          ? trainingData.associatedAttribute.map((attr: any) => attr.attributeDefinitionId)
          : [],
        integrationName: trainingData.organizationIntegrationName
      };

      setExistingTrainingData(cloneDeep(clonedData));
    }
  }, [mode, trainingData, attributes, integrationItems]);

  useEffect(() => {
    if (existingTrainingData && mode === CREATE_TRAINING_MODE.EDIT) {
      const current = getCurrentData();

      // Sort the competencies arrays for accurate comparison
      const currentForComparison = {
        ...current,
        competencies: [...current.competencies].sort()
      };

      const existingForComparison = {
        ...existingTrainingData,
        competencies: [...existingTrainingData.competencies].sort()
      };

      const hasChanged = !isEqual(currentForComparison, existingForComparison);

      setHasEdit(hasChanged);
    }
  }, [
    trainingName,
    requiredFaculties,
    recurrence,
    selectedCompetencies,
    selectedIntegrationId,
    existingTrainingData,
    mode
  ]);

  const handleTrainingNameChange = (e: any) => {
    setTrainingName(e.target.value);
  };

  const handleRequiredFacultiesChange = (e: any) => {
    setRequiredFaculties(e.target.value);
  };

  const handleRecurrenceChange = (e: any) => {
    setRecurrence(e.target.value);
  };

  const handleIntegrationChange = (e: any) => {
    setSelectedIntegrationId(e.target.value);
  };

  const handleCompetenciesChange = (event: any, newValue: any) => {
    setSelectedCompetencies(newValue);
  };

  const handleSave = () => {
    if (onSave) {
      const data = {
        courseId: mode === CREATE_TRAINING_MODE.EDIT ? trainingData.id : undefined,
        organizationIntegrationId: selectedIntegrationId.value,
        courseName: trainingName,
        faculties: requiredFaculties,
        recurrence: recurrence?.value,
        competencies: selectedCompetencies.map((comp: any) => comp.id)
      };
      onSave(data, mode);
    }
  };

  const isTrainingNameValid = (name: any) => {
    return name && name.length > 2 && name.length < 300;
  };

  const handleTrainingNameValidationText = (name: any) => {
    if (!name || name.length < 2) return "Please enter a valid name (between 2 and 300 characters)";
    if (!name || name.length > 300) return "Please enter a valid name (less than 300 characters)";
    return "";
  };

  const getCurrentData = (): ITeamTrainingDataType => {
    return {
      name: trainingName,
      faculties: requiredFaculties,
      recurrence: recurrence?.value,
      competencies: selectedCompetencies.map((comp: any) => comp.id),
      integrationName: selectedIntegrationId?.label
    };
  };

  const isEditMode = mode === CREATE_TRAINING_MODE.EDIT;

  useEffect(() => {
    onLoadAttributes();
  }, []);

  return (
    <Dialog
      open={true}
      theme={theme}
      onClose={onClose}
      fullWidth={true}
      maxWidth='sm'
      title={isEditMode ? "Edit Training" : "Create Training"}
      actions={[
        {
          label: isEditMode ? "Save" : "Create",
          primary: true,
          variant: "filled",
          disabled:
            !isTrainingNameValid(trainingName) ||
            !selectedIntegrationId?.value ||
            (isEditMode && !hasEdit),
          onClick: handleSave,
          isLoading: isLoading
        },
        {
          label: "Cancel",
          onClick: onClose
        }
      ]}
    >
      <Stack direction='column' spacing={4}>
        {error && (
          <Alert severity='error' sx={{ width: "100%" }}>
            {error}
          </Alert>
        )}

        {
          <Stack direction='column' spacing={1}>
            <SelectMenu
              label='Integration (required)'
              className='integration-selector-menu'
              value={selectedIntegrationId}
              firstValueDefault={false}
              clearable={false}
              floatLabel={false}
              dense={true}
              items={integrationItems}
              onChange={handleIntegrationChange}
              sx={{
                width: "100%",
                maxWidth: { xs: "auto", md: "100%" },
                "& fieldset legend span": {
                  display: "none"
                }
              }}
            />
          </Stack>
        }

        <TextField
          value={trainingName}
          label='Name of training (required)'
          dense={true}
          autoComplete={false}
          autoFocus={true}
          sx={{ width: "100%" }}
          onChange={handleTrainingNameChange}
          validationMethod={(e: any, value: any) => isTrainingNameValid(value)}
          validationText={handleTrainingNameValidationText(trainingName)}
        />

        <TextField
          value={requiredFaculties}
          label='Faculties (optional)'
          dense={true}
          autoComplete={false}
          sx={{ width: "100%" }}
          onChange={handleRequiredFacultiesChange}
        />

        <SelectMenu
          label='Recurrence (optional)'
          className='employee-filter-section-menu'
          value={recurrence}
          firstValueDefault={true}
          dense={true}
          disabled={false}
          items={Object.entries(COURSE_RECURRENCE)}
          onChange={handleRecurrenceChange}
          sx={{
            width: "100%",
            maxWidth: { xs: "auto", md: "100%" },
            "& fieldset legend span": {
              display: "none"
            }
          }}
        />

        <MultiAttributeSelector
          title='Competency'
          multiple={true}
          values={selectedCompetencies}
          onNewItemClick={onShowNewAttributeDialog}
          items={attributes ? attributes : []}
          getOptionLabel={(option: any) => option.name || ""}
          onChange={handleCompetenciesChange}
          hasNewItem={true}
        />
      </Stack>
    </Dialog>
  );
};

export default CreateTrainingDialog;
