import { CircularProgress, Stack } from "@mui/material";
import { Alert } from "klayowebshared";
import { Component } from "react";
import { AppContext } from "../../common/AppContext";
import { MenuSelect } from "../../components/MenuSelect";
import { TextField } from "../../components/TextField";
import { Dialog } from "../../components/dialogs/Dialog";
import { CREATE_TASK_MODE } from "../../constants";
export class CreateRegulationDomainDialog extends Component<any, any> {
  static contextType = AppContext;

  constructor(props: any) {
    super(props);

    this.state = {
      domain: null,
      region: null,
      regions: []
    };
  }

  componentDidMount() {
    const { mode, selectedRegion, selectedDomain } = this.props;
    if (mode === CREATE_TASK_MODE.EDIT) {
      this.setState({
        domain: selectedDomain.name,
        region: selectedDomain.regionRowId
      });
    }

    if (selectedRegion) {
      this.setState({ region: selectedRegion.id });
    }
    this.onFetchRegions();
  }

  async onFetchRegions() {
    const { onGetListRegulationRegion } = this.props;

    const regions = await onGetListRegulationRegion();

    this.setState({
      regions
    });
  }

  onSave() {
    const { onSave, selectedDomain } = this.props;
    const { domain, region } = this.state;
    if (onSave)
      onSave({
        domain,
        domainId: selectedDomain?.id,
        regionId: region
      });
  }

  onUpdateName(e: any) {
    this.setState({ domain: e.target.value });
  }

  onFormatListRegulationDomain(regions: any) {
    if (regions) {
      const newFormattedTypes = regions.reduce((acc: any, curr: any) => {
        acc[curr.id] = {
          id: curr.id,
          name: curr.name,
          value: curr.id,
          label: curr.name
        };
        return acc;
      }, {});
      return Object.values(newFormattedTypes);
    }
    return null;
  }

  onSelectItem(event: any) {
    this.setState({ region: event.target.value });
  }

  findSelectedRegion() {
    const { regions, region } = this.state;
    const { mode } = this.props;

    if (mode === CREATE_TASK_MODE.EDIT) {
      return regions.find((item: any) => item.id === region)?.name;
    }

    return "";
  }

  render() {
    const { user, theme, onClose, error, mode, selectedDomain, loading, selectedRegion } =
      this.props;
    const { domain, regions, region } = this.state;

    return (
      <Dialog
        open={true}
        theme={theme}
        onClose={onClose}
        fullWidth={true}
        maxWidth='sm'
        title={mode === CREATE_TASK_MODE.EDIT ? "Edit domain" : "Create domain"}
        actions={[
          {
            label: `${mode === CREATE_TASK_MODE.EDIT ? "Save" : "Create"}`,
            primary: true,
            disabled:
              !domain ||
              !region ||
              (mode === CREATE_TASK_MODE.EDIT &&
                domain === selectedDomain.name &&
                region === selectedDomain.regionRowId) ||
              loading,
            onClick: this.onSave.bind(this),
            icon: loading && <CircularProgress size={20} />
          },
          {
            label: "Cancel",
            onClick: onClose,
            disabled: loading
          }
        ]}
      >
        <Stack direction='column' spacing={4}>
          {error && (
            <Alert severity='error' sx={{ width: "100%" }}>
              {error}
            </Alert>
          )}

          <MenuSelect
            className='klayo_practical-type-select-menu'
            label='Jurisdiction (required)'
            value={region}
            firstValueDefault={false}
            noSelectionError={true}
            dense={true}
            items={this.onFormatListRegulationDomain(regions)}
            onChange={this.onSelectItem.bind(this)}
            sx={{ width: "100%" }}
            placeholder='Select jurisdiction'
            clearable={false}
            disabled={!!selectedRegion || !!selectedDomain}
          />

          <TextField
            value={domain}
            label='Domain name (required)'
            dense={true}
            disabled={user === null}
            autoComplete={false}
            autoFocus={false}
            sx={{ width: "100%" }}
            onChange={this.onUpdateName.bind(this)}
          />
        </Stack>
      </Dialog>
    );
  }
}
