import React, { useState, useContext, useEffect, useMemo } from "react";
import { Stack } from "@mui/material";
import { Alert } from "klayowebshared";
import { cloneDeep, isEqual } from "lodash";
import { AppContext } from "../../common/AppContext";
import { TextField } from "../../components/TextField";
import { Dialog } from "../../components/dialogs/Dialog";
import { CREATE_PROVIDER_MODE } from "../../constants";

interface IProviderDataType {
  name: string;
  abbreviation: string;
}

const CreateProviderDialog = (props: any) => {
  const {
    mode,
    onClose,
    onSave,
    theme,
    error,
    actionTask,
    newProviderName,
    providerData,
    isLoading
  } = props;

  const context = useContext(AppContext);
  const [longName, setLongName] = useState(newProviderName || "");
  const [shortName, setShortName] = useState("");
  const [hasEdit, setHasEdit] = useState(false);
  const [existingProviderData, setExistingProviderData] = useState<IProviderDataType | undefined>(
    undefined
  );

  // Set initial values if in edit mode
  useEffect(() => {
    if (mode === CREATE_PROVIDER_MODE.EDIT && providerData) {
      setLongName(providerData.name || "");
      setShortName(providerData.abbreviation || "");

      // Clone the provider data for comparison
      const clonedData: IProviderDataType = {
        name: providerData.name || "",
        abbreviation: providerData.abbreviation || ""
      };

      setExistingProviderData(cloneDeep(clonedData));
    }
  }, [mode, providerData]);

  // Check for changes when in edit mode
  useEffect(() => {
    if (existingProviderData && mode === CREATE_PROVIDER_MODE.EDIT) {
      const current = getCurrentData();
      const hasChanged = !isEqual(current, existingProviderData);
      setHasEdit(hasChanged);
    }
  }, [longName, shortName, existingProviderData, mode]);

  const handleLongNameChange = (e: any) => {
    setLongName(e.target.value);
  };

  const handleShortNameChange = (e: any) => {
    if (e.target.value.length <= 10) {
      setShortName(e.target.value);
    }
  };

  const getCurrentData = (): IProviderDataType => {
    return {
      name: longName,
      abbreviation: shortName
    };
  };

  const handleSave = () => {
    if (onSave) {
      onSave(longName, shortName, mode === CREATE_PROVIDER_MODE.EDIT ? providerData.id : null);
    }
  };

  const isLongNameValid = (name: any) => {
    return name && name.length > 2 && name.length < 100;
  };

  const handleLongNameValidationText = (name: any) => {
    if (!name || name.length < 2) return "Please enter a valid name (more than 2 characters)";
    if (!name || name.length > 100) return "Please enter a valid name (less than 100 characters)";
    return "";
  };
  const handleShortNameValidationText = (name: any) => {
    if (!name || name.length < 2) return "Please enter a valid name (more than 2 characters)";
    if (!name || name.length > 100) return "Please enter a valid name (less than 100 characters)";
    return "";
  };

  const isEditMode = mode === CREATE_PROVIDER_MODE.EDIT;

  return (
    <Dialog
      open={true}
      theme={theme}
      onClose={onClose}
      fullWidth={true}
      maxWidth='sm'
      title={isEditMode ? "Edit Provider" : "Create Provider"}
      actions={[
        {
          label: isEditMode ? "Save" : "Create",
          primary: true,
          variant: "filled",
          disabled:
            !isLongNameValid(longName) || (isEditMode && !hasEdit) || (!isEditMode && !shortName),
          onClick: handleSave,
          isLoading: isLoading
        },
        {
          label: "Cancel",
          onClick: onClose
        }
      ]}
    >
      <Stack direction='column' spacing={4}>
        {error && (
          <Alert severity='error' sx={{ width: "100%" }}>
            {error}
          </Alert>
        )}
        <TextField
          value={longName}
          label='Long name (required)'
          dense={true}
          autoComplete={false}
          autoFocus={true}
          sx={{ width: "100%" }}
          onChange={handleLongNameChange}
          validationMethod={(e: any, value: any) => isLongNameValid(value)}
          validationText={handleLongNameValidationText(longName)}
        />

        <TextField
          value={shortName}
          label='Short name (required)'
          dense={true}
          autoComplete={false}
          sx={{ width: "100%" }}
          onChange={handleShortNameChange}
          inputProps={{ maxLength: 10 }}
          helperText={`${shortName.length}/10`}
          validationMethod={(e: any, value: any) => isLongNameValid(value)}
          validationText={handleShortNameValidationText(shortName)}
        />
      </Stack>
    </Dialog>
  );
};

export default CreateProviderDialog;
