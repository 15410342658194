export class SettingsRegulationRegion {
  id!: string;
  name!: string;
  createdDate!: string;
  lastUpdatedDate!: string;
  numberOfDomains!: number;
  regulationDomains!: [];

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.createdDate = data.createdDate;
      this.lastUpdatedDate = data.lastUpdatedDate;
      this.numberOfDomains = data.numberOfDomains;
      this.regulationDomains = data.regulationDomains;
    }
  }
}
