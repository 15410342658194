import { isEmpty } from "lodash";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ErrorModel } from "../../common/models/ErrorModel";
import { Dialog } from "../../components/dialogs/Dialog";
import { AddEditRegulationView } from "../settings/AddEditRegulationView";

export const UpdateRegulationDialog = (props: any) => {
  const {
    theme,
    onBlockNavigation,
    onAllowNavigation,
    onNavigation,
    onGetListRegulationRegion,
    onGetListRegulationDomain,
    onGetListRegulationCategory,
    selectedRegulation,
    onSaveRegulation,
    onUpdateRegulation,
    onClose,
    onSaveRegulationSuccess,
    selectedCategory
  } = props;

  const editMode = !isEmpty(selectedRegulation);
  let editRegulationId: any = null;
  if (editMode) editRegulationId = selectedRegulation.id;

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const navigateStep = queryParams.get("navigateStep");
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const history = useHistory();

  const [saveRegulationError, setSaveRegulationError] = useState(null);

  const onSaveRegulationItem = async (regulation: any) => {
    try {
      setIsSaveLoading(true);
      let regulationResponse = null;
      if (editMode === true) {
        regulationResponse = await onUpdateRegulation(regulation, editRegulationId);
      } else {
        regulationResponse = await onSaveRegulation(regulation);
      }

      onSaveRegulationSuccess({
        ...regulationResponse.regulation,
        regulationDomainId: regulation.regulationDomainId,
        regulationRegionId: regulation.regulationRegionId
      });

      history.replace({ search: "" });
    } catch (error) {
      await setSaveRegulationError(ErrorModel.parseServerError(error));
    } finally {
      setIsSaveLoading(false);
    }
  };

  return (
    <Dialog
      open={true}
      theme={theme}
      onClose={onClose}
      disableEscapeKeyDown
      fullWidth={true}
      maxWidth='md'
      sx={{
        "& .MuiDialogContent-root": {
          padding: "36px"
        }
      }}
    >
      <AddEditRegulationView
        editMode={editMode}
        theme={theme}
        saveRegulationError={saveRegulationError}
        regulationId={editRegulationId}
        onBlockNavigation={onBlockNavigation}
        onAllowNavigation={onAllowNavigation}
        onNavigation={onNavigation}
        onCancel={onClose}
        onSave={onSaveRegulationItem}
        onGetListRegulationDomain={onGetListRegulationDomain}
        onGetListRegulationCategory={onGetListRegulationCategory}
        selectedRegulation={selectedRegulation}
        onGetListRegulationRegion={onGetListRegulationRegion}
        navigateStep={navigateStep}
        isSaveLoading={isSaveLoading}
        selectedCategory={selectedCategory}
      />
    </Dialog>
  );
};
