import React, { createContext, ReactNode, useContext, useState } from "react";

interface IGapMode {
  competencies: boolean;
  tasks: boolean;
  regulations: boolean;
  trainings: boolean;
}

interface IExpandRowIds {
  tasks: string[];
  regulations: string[];
  trainings: string[];
}

interface IKeywords {
  tasks: string;
  regulations: string;
  trainings: string;
}

interface IListViewMode {
  people: boolean;
}

interface TeamContextType {
  gapMode: IGapMode;
  setGapMode: (gapMode: IGapMode) => void;
  expandRowIds: IExpandRowIds;
  setExpandRowIds: (expandRowIds: IExpandRowIds) => void;
  keywords: IKeywords;
  setKeywords: (keywords: IKeywords) => void;
  listViewMode: IListViewMode;
  setListViewMode: (listViewMode: IListViewMode) => void;
  resetContextState: () => void;
}

export const TeamContext = createContext<TeamContextType | undefined>(undefined);

export const useTeamContext = () => {
  const context = useContext(TeamContext);
  if (!context) {
    throw new Error("useTeamContext must be used within a TeamProvider");
  }
  return context;
};

interface TeamProviderProps {
  children: ReactNode;
}

const defaultGapMode = {
  competencies: false,
  tasks: false,
  regulations: false,
  trainings: false
};

const defaultExpandRowIds = {
  tasks: [],
  regulations: [],
  trainings: []
};

const defaultKeywords = {
  tasks: "",
  regulations: "",
  trainings: ""
};

const defaultListViewMode = {
  people: false
};

export const TeamProvider: React.FC<TeamProviderProps> = ({ children }) => {
  const [gapMode, setGapMode] = useState<IGapMode>(defaultGapMode);
  const [expandRowIds, setExpandRowIds] = useState<IExpandRowIds>(defaultExpandRowIds);
  const [keywords, setKeywords] = useState<IKeywords>(defaultKeywords);
  const [listViewMode, setListViewMode] = useState<IListViewMode>(defaultListViewMode);

  const resetContextState = () => {
    setGapMode(defaultGapMode);
    setExpandRowIds(defaultExpandRowIds);
    setKeywords(defaultKeywords);
    setListViewMode(defaultListViewMode);
  };

  return (
    <TeamContext.Provider
      value={{
        gapMode,
        setGapMode,
        expandRowIds,
        setExpandRowIds,
        keywords,
        setKeywords,
        listViewMode,
        setListViewMode,
        resetContextState
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};
