import React, { Component } from "react";
import { Dialog } from "../../components/dialogs/Dialog";
import { LoadingBar } from "../../components/LoadingBar";
import { TextField } from "../../components/TextField";
import { cloneDeep } from "lodash";

export class AddEditLocationDialog extends Component<any, any> {
  originalLocation: { locationId: any; name: string } | null = null;

  constructor(props: any) {
    super(props);

    const locationId = props.location ? props.location.locationId : null;
    const locationName = props.location ? props.location.name : "";

    this.state = {
      locationId: locationId,
      locationName: locationName,
      valid: this.isValid(locationName),
      saving: false,
      hasEdit: false
    };

    if (props.location) {
      this.originalLocation = cloneDeep({
        locationId: locationId,
        name: locationName
      });
    }
  }

  onChange(e: any) {
    const newLocationName = e.target.value;

    const hasEdit = this.checkForEdits(newLocationName);

    this.setState({
      locationName: newLocationName,
      valid: this.isValid(newLocationName),
      hasEdit: hasEdit
    });
  }

  isValid(locationName: any) {
    return locationName != null && locationName.length > 2;
  }

  checkForEdits(newName: string) {
    if (!this.originalLocation) {
      return newName.length > 0;
    }

    return newName !== this.originalLocation.name;
  }

  onSave() {
    const { locationId, locationName } = this.state;
    this.setState({ saving: true });
    if (this.props.onSave) this.props.onSave(locationId, locationName);
  }

  render() {
    const { theme, onClose, saving: propsSaving, error } = this.props;
    const { locationId, locationName, valid, hasEdit, saving: stateSaving } = this.state;

    const saving = propsSaving || stateSaving;

    const saveEnabled = valid && (locationId === null || hasEdit);

    return (
      <Dialog
        open={true}
        theme={theme}
        title={locationId ? "Edit location" : "New location"}
        onClose={onClose}
        fullWidth={true}
        maxWidth='sm'
        actions={[
          {
            label: locationId ? "Save" : "Add location",
            primary: true,
            disabled: !saveEnabled || saving,
            onClick: this.onSave.bind(this)
          },
          {
            label: "Cancel",
            onClick: onClose
          }
        ]}
      >
        {saving && <LoadingBar loading={true} />}
        <TextField
          label='Location'
          placeholder='Location'
          autoComplete={false}
          autoFocus={true}
          sx={{ width: "100%" }}
          error={error !== null}
          helperText={error}
          value={locationName}
          onChange={this.onChange.bind(this)}
        />
      </Dialog>
    );
  }
}
