import { Table } from "./Table";

import { Tooltip } from "@mui/material";
import { KLAYO_COLORS } from "../../themes";
import { Chip } from "../Chip";

export class TeamTaskTable extends Table {
  static columns = {
    attribute: {
      field: "attribute",
      label: "Competency"
    },
    course: {
      field: "courseName",
      label: "Course"
    },
    gapEmployees: {
      field: "gapEmployees",
      label: "Gaps"
    },
    gapEmployeesCount: {
      field: "gapEmployeesCount",
      label: "Gaps"
    },
    attributeEmployees: {
      field: "attributeEmployees",
      label: "People"
    },
    attributeEmployeesCount: {
      field: "attributeEmployeesCount",
      label: "People"
    },
    competencies: {
      field: "competencies",
      label: "Competencies"
    },
    summaryWithPeople: {
      field: "summaryWithPeople",
      label: "Summary",
      visibleToBreakpoint: "md"
    }
  };

  static defaultProps = {
    ...Table.defaultProps,
    columns: [],
    noRowsMessage: "No tasks, yet",
    showProficiency: false,
    rowHasAction: null,
    disableSelection: true
  };

  constructor(props: any) {
    super(props);

    this.state = {
      ...this.state,
      sortModel: this.props.sortModel ? this.props.sortModel : []
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    super.componentDidUpdate(prevProps, prevState);
    if (prevProps.showProficiency !== this.props.showProficiency) {
      this.initColumns();
      this.forceUpdate();
    }
  }

  getRowId(e: any) {
    return e.rowId;
  }

  highlightText(text: string, keyword: string) {
    const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    return text.replace(new RegExp(escapedKeyword, "gi"), (match) => `<strong>${match}</strong>`);
  }

  initColumns() {
    const { columns, sortable, keyword } = this.props;

    this.columns = [];

    columns.forEach((c: any) => {
      if (this.hasColumn(c)) {
        if (c.type === TeamTaskTable.columns.attribute)
          this.columns.push({
            field: c.field || TeamTaskTable.columns.attribute.field,
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            flex: 1,
            filterable: false,
            disableReorder: true,
            renderCell: (params: any) => {
              const name = params?.row?.name;
              const isExpanded = params.rowNode.childrenExpanded;
              const paddingLeft = `${params.rowNode.depth * 24}px`;
              return (
                <div
                  style={{
                    fontWeight: isExpanded ? "bold" : "normal",
                    paddingLeft: paddingLeft
                  }}
                  className='truncate'
                >
                  <Tooltip title={name}>
                    <span>
                      {keyword ? (
                        <span
                          dangerouslySetInnerHTML={{ __html: this.highlightText(name, keyword) }}
                        />
                      ) : (
                        name
                      )}
                    </span>
                  </Tooltip>
                </div>
              );
            },
            valueGetter: (params: any) => params?.row?.name
          });

        if (c.type === TeamTaskTable.columns.course)
          this.columns.push({
            field: c.field || TeamTaskTable.columns.course.field,
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            flex: 1,
            filterable: false,
            disableReorder: true,
            renderCell: (params: any) => {
              const name = params?.row?.name;
              const isExpanded = params.rowNode.childrenExpanded;
              const paddingLeft = `${params.rowNode.depth * 24}px`;
              return (
                <div
                  style={{
                    fontWeight: isExpanded ? "bold" : "normal",
                    paddingLeft: paddingLeft
                  }}
                  className='truncate'
                >
                  <Tooltip title={name}>
                    <span>
                      {keyword ? (
                        <span
                          dangerouslySetInnerHTML={{ __html: this.highlightText(name, keyword) }}
                        />
                      ) : (
                        name
                      )}
                    </span>
                  </Tooltip>
                </div>
              );
            },
            valueGetter: (params: any) => params?.row?.name
          });

        if (c.type === TeamTaskTable.columns.gapEmployees)
          this.columns.push({
            field: c.field || TeamTaskTable.columns.gapEmployees.field,
            headerName: c.label || "Gaps",
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
            width: 100,
            filterable: false,
            disableReorder: true,
            align: "center",
            headerAlign: "center",
            renderCell: (params: any) =>
              params.row.gapEmployees?.length ? (
                <Chip
                  label={params.row.gapEmployees?.length}
                  onClick={(e: any) => this.props.onGapSelect(e, params.row)}
                  textColor={KLAYO_COLORS.Red600}
                  backgroundColor={KLAYO_COLORS.Rose050}
                />
              ) : (
                0
              ),
            valueGetter: (params: any) => {
              return params.row.gapEmployees?.length || 0;
            }
          });
        if (c.type === TeamTaskTable.columns.gapEmployeesCount)
          this.columns.push({
            field: c.field || TeamTaskTable.columns.gapEmployeesCount.field,
            headerName: c.label || "Gaps",
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
            width: 100,
            filterable: false,
            disableReorder: true,
            align: "center",
            headerAlign: "center",
            renderCell: (params: any) =>
              params.row.gapEmployeesCount ? (
                <Chip
                  label={params.row.gapEmployeesCount}
                  onClick={(e: any) => this.props.onGapSelect(e, params.row)}
                  textColor={KLAYO_COLORS.Red600}
                  backgroundColor={KLAYO_COLORS.Rose050}
                />
              ) : (
                0
              ),
            valueGetter: (params: any) => {
              return params.row.gapEmployeesCount || 0;
            }
          });

        if (c.type === TeamTaskTable.columns.attributeEmployees)
          this.columns.push({
            field: c.field || TeamTaskTable.columns.attributeEmployees.field,
            headerName: c.label || "People",
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
            width: 100,
            filterable: false,
            disableReorder: true,
            align: "center",
            headerAlign: "center",
            renderCell: (params: any) =>
              !params.row.isHiddenPeople &&
              (params.row.employees?.length ? (
                <Chip
                  label={params.row.employees?.length}
                  onClick={(e: any) => this.props.onPeopleSelect(e, params.row)}
                  textColor={KLAYO_COLORS.GreyGR900}
                  backgroundColor={KLAYO_COLORS.GrayGR200}
                />
              ) : (
                0
              )),
            valueGetter: (params: any) => {
              return params.row.employees?.length || 0;
            }
          });
        if (c.type === TeamTaskTable.columns.attributeEmployeesCount)
          this.columns.push({
            field: c.field || TeamTaskTable.columns.attributeEmployeesCount.field,
            headerName: c.label || "People",
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
            width: 100,
            filterable: false,
            disableReorder: true,
            align: "center",
            headerAlign: "center",
            renderCell: (params: any) =>
              !params.row.isHiddenPeople &&
              (params.row.employeesCount ? (
                <Chip
                  label={params.row.employeesCount}
                  onClick={(e: any) => this.props.onPeopleSelect(e, params.row)}
                  textColor={KLAYO_COLORS.GreyGR900}
                  backgroundColor={KLAYO_COLORS.GrayGR200}
                />
              ) : (
                0
              )),
            valueGetter: (params: any) => {
              return params.row.employeesCount || 0;
            }
          });
        if (c.type === TeamTaskTable.columns.competencies)
          this.columns.push({
            field: c.field || TeamTaskTable.columns.competencies.field,
            headerName: c.label,
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
            width: 200,
            filterable: false,
            disableReorder: true,
            align: "center",
            headerAlign: "center",
            renderCell: (params: any) => {
              return <span>{params.row.competencies?.length}</span>;
            },
            valueGetter: (params: any) => params.row.competencies?.length
          });

        if (c.type === TeamTaskTable.columns.summaryWithPeople)
          this.columns.push({
            field: c.field || TeamTaskTable.columns.summaryWithPeople.field,
            headerName: c.label || c.type.label,
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
            filterable: false,
            flex: 1,
            disableReorder: true,
            renderCell: (params: any) => {
              const isExpanded = params.rowNode.childrenExpanded;

              return (
                <div className='klayo-table_multilinerow'>
                  <div
                    className='klayo-attrtable_multilinerow_row1'
                    style={{
                      fontWeight: isExpanded ? "bold" : "normal"
                    }}
                  >
                    {params.row.name}
                  </div>
                  <div
                    className='klayo-attrtable_multilinerow_row3'
                    style={{ display: "flex", color: "#6B7280" }}
                  >
                    {params.row.type === "training" && (
                      <>
                        <span>{params.row.competencies?.length} competencies</span>
                        <span>, </span>
                      </>
                    )}
                    <span>{params.row.employees?.length} people</span>
                    <span>, </span>
                    <span>{params.row.gapEmployees?.length} gaps</span>
                  </div>
                </div>
              );
            }
          });
      }
    });
  }
}
