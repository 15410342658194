import styled from "@emotion/styled";
import { Component } from "react";
import { TextField } from "../../components/TextField";
import { IntegrationSource } from "../../constants";

export class MultiStudentIdTextField extends Component<any, any> {
  render() {
    const {
      employee,
      organization,
      integration,
      saving,
      employeeLoaded,
      onStudentIdChange,
      isStudentIdValid
    } = this.props;

    const integrations = integration?.integrations.length
      ? integration.integrations.filter(
          (integration: any) => integration.integrationSource !== IntegrationSource.Manual
        )
      : [];

    return (
      <MultiStudentIdTextFieldStyled className='klayo_multi-student-id-text-field'>
        {!!integrations.length &&
          integrations.map((item: any) => {
            const labelAbbreviation = item.abbreviation ? item.abbreviation : item.name;
            const label = organization.hasMultipleIntegrations
              ? `Student ${labelAbbreviation}`
              : `Student ID`;
            let name = "";

            // fill data in text field
            if (employee) {
              if (employee.integrationUsers && employee.integrationUsers.length > 0) {
                const filledIntegration = employee?.integrationUsers.find(
                  (integrationUser: any) => integrationUser.organizationIntegrationId === item.id
                );

                name = filledIntegration ? filledIntegration.username : "";
              }
            }

            return (
              <TextField
                label={label}
                clearable={false}
                onChange={(e: any) => onStudentIdChange(e, item.id)}
                value={name}
                disabled={saving}
                validationMethod={(e: any, value: any) => isStudentIdValid(employee)}
                validationText='Turn off to specify different student IDs for each provider, turn on to use the same ID for all.'
                loading={!employeeLoaded}
                sx={{ width: "100%" }}
                key={item.id}
              />
            );
          })}
      </MultiStudentIdTextFieldStyled>
    );
  }
}

const MultiStudentIdTextFieldStyled = styled.div`
  &.klayo_multi-student-id-text-field {
    display: flex;
    flex-direction: column;
    gap: 44px;

    .klayo-textfield .MuiInputLabel-root {
      width: 93%;
    }
  }
`;
