import { Data } from "../Data";
import { Provider, Location } from "../../interfaces/ProviderTraining";

export class OrganizationSettings extends Data {
  static instance: any = null;
  locations: Location[];
  organizationId: any;
  name: any;
  organizationIntegrations: Provider[];
  showProficiency: any;
  address: any;
  groupLevelTypes: any;
  groupNotificationTypes: any;
  enableJobMatching: any;
  allowJobMatchingConfig: any;

  constructor(options: any) {
    super({
      api: "/Organization/Admin",
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });

    this.locations = [];
    this.organizationIntegrations = [];
  }

  onDataReceived(data: any) {
    this.organizationId = data.organization.organizationId;
    this.name = data.organization.name;
    this.organizationIntegrations = data.organization.organizationIntegrations;
    this.showProficiency = data.organization.showProficiency;
    this.address = data.organization.address;
    this.enableJobMatching = data.organization.enableJobMatching;
    this.allowJobMatchingConfig = data.organization.allowJobMatchingConfig;
    this.groupLevelTypes = data.organization.levelTypes.map((l: any) => {
      return { id: l.groupManagerLevelTypeId, label: l.name };
    });
    this.groupNotificationTypes = data.organization.notificationTypes.map((l: any) => {
      return { id: l.groupManagerNotificationTypeId, label: l.name };
    });

    data.organization.locations.forEach((location: Location) => this.locations.push(location));

  }
  // provider
  getProvider(id: string) {
    return this.organizationIntegrations.find((provider: Provider) => provider.id === id);
  }

  updateProvider(provider: Provider) {
    console.log("provider", provider);
    const index = this.organizationIntegrations.findIndex((item: Provider) => item.id === provider.id);
    if (index !== -1) {
      const providers = [...this.organizationIntegrations];
      providers.splice(index, 1, provider);
      this.organizationIntegrations = providers;
    }
  }

  addProvider(provider: Provider) {
    this.organizationIntegrations = [...this.organizationIntegrations];
    this.organizationIntegrations.push(provider);
  }

  deleteProvider(provider: Provider) {
    const providers = [...this.organizationIntegrations];
    const index = providers.indexOf(provider);
    if (index !== -1) {
      providers.splice(index, 1);
      this.organizationIntegrations = providers;
    }
  }
  //
  getLocation(id: string) {
    return this.locations.find((location: Location) => location.locationId === id);
  }

  updateLocation(location: Location) {
    const locations = [...this.locations];

    const updatedLocation = this.getLocation(location.locationId)

    if (!updatedLocation) return;

    const index = locations.indexOf(updatedLocation);
    if (index !== -1) locations.splice(index, 1, location);
    this.locations = [...locations];
  }

  addLocation(location: Location) {
    this.locations = [...this.locations];
    this.locations.push(location);
  }

  deleteLocation(location: Location) {
    const locations = [...this.locations];
    const index = locations.indexOf(location);
    if (index !== -1) locations.splice(index, 1);
    this.locations = [...locations];
  }

  deleteAddress() {
    this.address = null;
  }

  addAddress(address: any) {
    this.address = address;
  }

  static get(context: any, cached = true) {
    return new Promise((resolve: any, reject) => {


      if (OrganizationSettings.instance && cached) resolve(OrganizationSettings.instance, true);
      else OrganizationSettings.instance = new OrganizationSettings({ resolve, reject, context });
    });
  }
}
