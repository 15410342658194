import { SettingsAttribute } from "./SettingsAttribute";
import { SettingsTask } from "./SettingsTask";

export class SettingsJob {
  id: any;
  rowId: any;
  isJob: any;
  jobDefinitionId: any;
  name: any;
  description: any;
  numberOfPositions: any;
  jobDefinitionDomainId: any;
  attributes: any;
  regulationTasks: any;
  numberOfCompetencies: any;
  numberOfRegulationTasks: any;

  constructor(data?: any) {
    this.attributes = [];
    this.regulationTasks = [];

    if (data) {
      this.id = data.jobDefinitionId;
      this.rowId = data.jobDefinitionId;
      this.isJob = true;
      this.jobDefinitionId = data.jobDefinitionId;
      this.name = data.name;
      this.description = data.description;
      this.numberOfPositions = data.jobs ? data.jobs.length : 0;
      this.jobDefinitionDomainId = data.jobDefinitionDomainId;
      this.numberOfCompetencies = data.numberOfCompetencies;
      this.numberOfRegulationTasks = data.numberOfRegulationTasks;
      const attrs = data.jobAttributes || data.attributes;
      if (attrs) {
        attrs.forEach((a: any) => {
          const attr = new SettingsAttribute(a);
          //this.addAttribute(attr);
          this.attributes.push(attr);
          this.attributes = this.attributes.concat(attr.childAttributes);
        });
      }
      const listTasks = data.regulationTasks || this.regulationTasks;
      if (listTasks) {
        listTasks.forEach((t: any) => {
          const task = new SettingsTask(t);
          this.regulationTasks.push(task);
        });
      }
    }
  }

  isAttributesSame(other: any) {
    if (!other) return false;
    const otherNoChildren = other.filter((a: any) => !a.isChild);
    if (other.length !== this.attributes.length) return false;

    for (const o of otherNoChildren) {
      const match = this.attributes.find((a: any) => a.attributeId === o.attributeId && !a.isChild);
      if (!match) return false;

      if (match.requirementType && o.requirementType) {
        if (match.requirementType.index !== o.requirementType.index) return false;
      }
    }
    return true;
  }

  addAttribute(attr: any) {
    this.attributes = [...this.attributes, attr];
    //this.attributes.push(attr);

    //if(attr.childAttributes) attr.childAttributes.forEach(child => this.addAttribute(child));
  }

  toApiDto() {
    const attrs: any = [];
    this.attributes
      .filter((a: any) => a.isChild !== true)
      .forEach((a: any) => {
        attrs.push({
          attributeDefinitionId: a.attributeDefinitionId,
          jobAttributeRequirementType:
            a.requirementType && a.isParent !== true ? a.requirementType.index : null,
          proficiencyMinimum: a.hasProficiency ? a.proficiencyMinimum || 1 : null,
          isMinimum: a.isMinimum
        });

        /*a.childAttributes.forEach(child => {
                if (!attrs.some(a => a.attributeDefinitionId === child.attributeDefinitionId)) attrs.push({
                    attributeDefinitionId: child.attributeDefinitionId,
                    proficiencyValue: child.proficiencyMinimum,
                    comment: child.comment
                });
            });*/
      });

    return {
      regulationTaskIds: this.regulationTasks ? this.regulationTasks.map((r: any) => r.id) : [],
      jobDefinitionId: this.jobDefinitionId,
      name: this.name,
      description: this.description,
      jobDefinitionDomainId: this.jobDefinitionDomainId?.id || this.jobDefinitionDomainId,
      jobAttributes:
        attrs /*Object.values(this.attributes).filter(a => a.isChild !== true).map(a => {
                return {
                    attributeDefinitionId: a.attributeDefinitionId,
                    jobAttributeRequirementType: a.requirementType && a.isParent !== true ? a.requirementType.index : null,
                    proficiencyMinimum: 0,
                    jobAttributeChildren: a.childAttributes ? Object.values(a.childAttributes).map(c => {
                        return {
                            attributeDefinitionId: c.attributeDefinitionId,
                            jobAttributeRequirementType: c.requirementType ? c.requirementType.index : null,
                            proficiencyMinimum: 0
                        }
                    }) : null
                }
            })*/
    };
  }
}
