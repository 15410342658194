import { Stack } from "@mui/material";
import { EditTemplate } from "klayowebshared";
import { cloneDeep, isEqual } from "lodash";
import { Component } from "react";
import { AppContext } from "../../common/AppContext";
import axiosClient from "../../common/AxiosClient";
import { Alert } from "../../components/Alert";
import { PaperOrDialog } from "../../components/PaperOrDialog";
import { TextField } from "../../components/TextField";
import { SettingsAttributeCategory } from "../../data/settings/SettingsAttributeCategory";

export class AddEditCategoryView extends Component<any, any> {
  static contextType = AppContext;

  existingCategory: any;

  constructor(props: any) {
    super(props);

    this.state = {
      category: props.category,
      valid: this.isValid(props.category?.name || ""),
      saving: false,
      error: null,
      hasEdit: false
    };

    this.existingCategory = null;
  }

  componentDidMount(): void {
    this.loadCategory();
  }

  async loadCategory() {
    const { categoryId, category } = this.props;

    const attributeCategoryId = categoryId || category?.attributeCategoryId;

    if (!attributeCategoryId) return;

    try {
      this.context.setLoading("AttributeCategory", true);
      const res = await axiosClient.get(
        `/AttributeDefinition/AttributeCategory/${attributeCategoryId}`
      );

      const attributeCategory = new SettingsAttributeCategory(res.data);

      this.existingCategory = cloneDeep(attributeCategory);

      this.setState({
        category: cloneDeep(attributeCategory)
      });
    } catch (error) {
    } finally {
      this.context.setLoading("AttributeCategory", false);
    }
  }

  isValid(categoryName: any) {
    return categoryName != null && categoryName.length > 2;
  }

  onNameChange(e: any) {
    const { category } = this.state;
    category.name = e.target.value;

    const hasEdit = !isEqual(this.existingCategory?.name, category.name);

    this.setState({
      category,
      valid: this.isValid(category.name),
      hasEdit
    });
  }

  onSave() {
    const { onSave, onBlockNavigation } = this.props;
    const { category } = this.state;

    if (onSave) {
      if (onBlockNavigation) onBlockNavigation(false, "Category");
      this.setState({ saving: true });
      onSave(category);
    }
  }

  onEditStateChange(hasEdit: any) {
    this.setState({ hasEdit });
  }

  onNavigation(e: any, callback: any) {
    const { onNavigation } = this.props;
    return onNavigation && onNavigation(e, callback);
  }

  onCancel(e: any, source: any) {
    const { hasEdit } = this.state;

    if (hasEdit) {
      this.onNavigation(e, this.stopBlockingNavAndClose.bind(this));
    } else {
      this.stopBlockingNavAndClose(e);
    }
  }

  stopBlockingNavAndClose(e: any) {
    const { onCancel, onBlockNavigation } = this.props;
    if (onBlockNavigation) onBlockNavigation(false, "Category");
    if (onCancel) onCancel(e);
  }

  render() {
    const { theme, editMode, dialogMode, error, onAllowNavigation, onBlockNavigation } = this.props;
    const { category, valid, hasEdit, saving } = this.state;

    const actualHasEdit = this.existingCategory
      ? !isEqual(this.existingCategory.name, category?.name)
      : false;

    return (
      <EditTemplate
        theme={theme}
        name='Category'
        onAllowNavigation={onAllowNavigation}
        onBlockNavigation={onBlockNavigation}
        onEditStateChange={this.onEditStateChange.bind(this)}
        detectEdit={!saving}
        compare={{
          existing: this.existingCategory,
          editing: category,
          members: [{ name: "name" }],
          compareFunc: (existing: any, editing: any) => {
            if (!existing) return false;
            return !isEqual(existing.name, editing?.name);
          }
        }}
      >
        <PaperOrDialog
          theme={theme}
          dialog={dialogMode}
          dialogToBreakpoint='md'
          paperPadding='60px'
          title={
            editMode
              ? "Competency category details"
              : dialogMode
                ? "Create new competency category"
                : "Create competency category"
          }
          titleSx={{ fontSize: { xs: "20px", md: "30px" }, fontWeight: "bold" }}
          titleSize={1}
          backButton={editMode}
          onBack={(e: any) => this.onCancel(e, "back")}
          actions={[
            {
              label: editMode ? "Save" : "Create",
              primary: true,
              disabled: !valid || (editMode && !actualHasEdit) || saving,
              onClick: this.onSave.bind(this)
            },
            {
              label: "Cancel",
              onClick: (e: any) => this.onCancel(e, "cancel")
            }
          ]}
          justifyActions='start'
        >
          <Stack direction='column' spacing={4}>
            {error && (
              <Alert severity='error' sx={{ width: "100%" }}>
                {error}
              </Alert>
            )}
            <TextField
              label='Category name'
              autoComplete={false}
              autoFocus={true}
              clearable={false}
              sx={{ width: "100%" }}
              value={category?.name || ""}
              onChange={this.onNameChange.bind(this)}
              validationMethod={(e: any, value: any) => this.isValid(category?.name)}
              validationText='Please enter a valid name (more than 2 characters)'
            />
          </Stack>
        </PaperOrDialog>
      </EditTemplate>
    );
  }
}
