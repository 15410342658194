import styled from "@emotion/styled";
import { ListItemText, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import { GridActionsCellItem, GridGroupNode, useGridApiRef } from "@mui/x-data-grid-pro";
import { ConfirmationDialog } from "klayowebshared";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Utils } from "../../common/Utils";
import { ErrorModel } from "../../common/models/ErrorModel";
import { Button } from "../../components/Button";
import { Paper } from "../../components/Paper";
import { SectionLoader } from "../../components/SectionLoader";
import { Snackbar } from "../../components/Snackbar";
import { TablePlaceholder } from "../../components/TablePlaceholder";
import { TextField } from "../../components/TextField";
import { RegulationsTable } from "../../components/table/RegulationsTable";
import { CREATE_TASK_MODE, REGULATION_ATTRIBUTE_TYPE } from "../../constants";
import { ReactComponent as AddIcon } from "../../resources/images/icons-add.svg";
import { ReactComponent as AttributesIcon } from "../../resources/images/icons-attribute.svg";
import { ReactComponent as ChevronDownWhiteIcon } from "../../resources/images/icons-chevron-down-white.svg";
import { ReactComponent as MoreVertIcon } from "../../resources/images/icons-more-vertical.svg";
import { ReactComponent as SearchIcon } from "../../resources/images/icons-search.svg";
import { KLAYO_COLORS } from "../../themes";
import { CreateRegulationCategoryDialog } from "../settings/CreateRegulationCategoryDialog";
import { CreateRegulationDomainDialog } from "../settings/CreateRegulationDomainDialog";
import { UpdateRegulationDialog } from "./UpdateRegulationDialog";

export function SettingsRegulationsView(props: any) {
  const {
    history,
    theme,
    user,
    onGetListRegulationDomain,
    onGetListRegulationCategory,
    onGetListRegulationByCategoryId,
    listRegulationDomain,
    listAllRegulationDomain,
    onGetListRegulationRegion,
    onUpdateRegulation,
    onSaveRegulation,
    onBlockNavigation,
    onAllowNavigation,
    onNavigation
  } = props;

  const [selectedRowElement, setSelectedRowElement] = useState<any>(null);
  const [search, setSearch] = useState("");
  const [domainAndCategoryActionMenuElement, setDomainAndCategoryActionMenuElement] =
    useState<any>(null);
  const [isShowRegulationDomainDialog, setIsShowRegulationDomainDialog] = useState(false);
  const [createRegulationDomainMode, setCreateRegulationDomainMode] = useState<any>(null);
  const [selectedDomain, setSelectedDomain] = useState<any>(null);
  const [isShowRegulationCategoryDialog, setIsShowRegulationCategoryDialog] = useState(false);
  const [createRegulationCategoryMode, setCreateRegulationCategoryMode] = useState<any>(null);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [regulationDomainError, setRegulationDomainError] = useState<any>(null);
  const [regulationCategoryError, setRegulationCategoryError] = useState<any>(null);
  const [selectedRegion, setSelectedRegion] = useState<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [isDeleteRow, setIsDeleteRow] = useState(false);
  const [deletingRowError, setDeletingRowError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState<any>(null);
  const [isExistRegulationDomain, setIsExistRegulationDomain] = useState(true);

  const [isRegulationLoading, setIsRegulationLoading] = useState(false);
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isShowUpdateRegulationDialog, setIsShowUpdateRegulationDialog] = useState(false);
  const [selectedRegulation, setSelectedRegulation] = useState<any>(null);
  const [deletingRowType, setDeletingRowType] = useState<any>(null);
  const [regions, setRegions] = useState([]);

  const apiRef = useGridApiRef();
  const searchRef = useRef("");
  const isExistedRegion = useRef(false);

  const preservedExpandedRows = useRef<any[]>([]);
  const isMounted = useRef(true);

  const getTableRows = () => {
    return Utils.getAllRowIds(apiRef);
  };

  const fetchRegions = async (isShowLoading = false) => {
    try {
      isShowLoading && setLoading(true);
      setRegions([]);
      const listRegulationRegion = await onGetListRegulationRegion(searchRef.current);

      if (!isExistedRegion.current) {
        isExistedRegion.current = !!listRegulationRegion.length;
      }

      const regionRows = listRegulationRegion.map((region: any) => {
        return {
          ...region,
          hierarchy: [region.id],
          type: REGULATION_ATTRIBUTE_TYPE.REGION,
          descendantCount: region.numberOfDomains
        };
      });

      Utils.updateRows(apiRef, regionRows);

      // Expand the rows that were previously expanded and are of type REGION
      preservedExpandedRows.current
        .filter(
          (row: any) =>
            row.type === REGULATION_ATTRIBUTE_TYPE.REGION &&
            regionRows.some((r: any) => r.id === row.id)
        )
        .forEach((row: any) => {
          apiRef.current.setRowChildrenExpansion(row.id, true);
        });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchDomains = async (regionId: string, node: GridGroupNode, row: any) => {
    const listRegulationDomain = await onGetListRegulationDomain(regionId, searchRef.current);

    setIsExistRegulationDomain(listRegulationDomain.length > 0);

    const domainRows = listRegulationDomain.map((domain: any) => {
      return {
        ...domain,
        hierarchy: [...row.hierarchy, domain.id],
        type: REGULATION_ATTRIBUTE_TYPE.DOMAIN,
        descendantCount: domain.numberOfCategories,
        regionRowId: row.id
      };
    });

    Utils.updateRows(apiRef, [
      ...domainRows,
      { ...row, childrenFetched: true },
      { id: `placeholder-children-${node.id}`, _action: "delete" }
    ]);

    // Expand the rows that were previously expanded and are of type DOMAIN
    const expandedDomains = preservedExpandedRows.current.filter(
      (row: any) =>
        row.type === REGULATION_ATTRIBUTE_TYPE.DOMAIN &&
        domainRows.some((r: any) => r.id === row.id)
    );

    if (expandedDomains.length) {
      expandedDomains.forEach(async (domainRow: any) => {
        apiRef.current.setRowChildrenExpansion(domainRow.id, true);
      });
    }
  };

  const fetchCategories = async (node: GridGroupNode, row: any) => {
    const regulationCategories = await onGetListRegulationCategory(row.id, searchRef.current);
    const categoryRows = regulationCategories.map((category: any) => ({
      ...category,
      hierarchy: [...row.hierarchy, category.id],
      type: REGULATION_ATTRIBUTE_TYPE.CATEGORY,
      descendantCount: category.numberOfRegulations || 0,
      domainRowId: row.id
    }));

    Utils.updateRows(apiRef, [
      ...categoryRows,
      { ...row, childrenFetched: true },
      { id: `placeholder-children-${node.id}`, _action: "delete" }
    ]);

    // Expand the rows that were previously expanded and are of type CATEGORY
    const expandedCategories = preservedExpandedRows.current.filter(
      (row: any) =>
        row.type === REGULATION_ATTRIBUTE_TYPE.CATEGORY &&
        categoryRows.some((r: any) => r.id === row.id)
    );

    if (expandedCategories.length) {
      expandedCategories.forEach(async (categoryRow: any) => {
        apiRef.current.setRowChildrenExpansion(categoryRow.id, true);
      });
    }
  };

  const fetchRegulations = async (node: GridGroupNode, row: any) => {
    const listRegulation = await onGetListRegulationByCategoryId(row.id, searchRef.current);
    if (!listRegulation.length) {
      Utils.updateRows(apiRef, [
        {
          ...row,
          childrenFetched: true,
          descendantCount: 0
        },
        { id: `placeholder-children-${node.id}`, _action: "delete" }
      ]);
      return;
    }
    const regulationRows = listRegulation.map((regulation: any) => ({
      ...regulation,
      hierarchy: [...row.hierarchy, regulation.id],
      type: REGULATION_ATTRIBUTE_TYPE.REGULATION,
      numberOfRegulationTasks: regulation.regulationTasks.length || 0,
      categoryId: row.id
    }));
    Utils.updateRows(apiRef, [
      ...regulationRows,
      { ...row, childrenFetched: true },
      { id: `placeholder-children-${node.id}`, _action: "delete" }
    ]);
  };

  useEffect(() => {
    if (!isMounted.current) return;

    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    fetchRegions(true);

    isMounted.current = false;

    return apiRef.current.subscribeEvent("rowExpansionChange", handleRowExpansionChange);
  }, [apiRef]);

  const handleRowExpansionChange = async (node: GridGroupNode) => {
    const row = apiRef.current.getRow(node.id);

    if (!node.childrenExpanded || !row || row.childrenFetched) {
      return;
    }

    switch (row.type) {
      case REGULATION_ATTRIBUTE_TYPE.DOMAIN:
        await fetchCategories(node, row);
        break;
      case REGULATION_ATTRIBUTE_TYPE.CATEGORY:
        await fetchRegulations(node, row);
        break;

      default:
        await fetchDomains(row.id, node, row);
        break;
    }

    if (node.childrenExpanded) {
      const existedRow = preservedExpandedRows.current.find((r: any) => r.id === node.id);

      if (!existedRow) {
        preservedExpandedRows.current.push(row);
      }
    } else {
      preservedExpandedRows.current = preservedExpandedRows.current.filter(
        (r: any) => r.id !== node.id
      );
    }
  };

  const searchDebounce = useCallback(
    debounce((value) => {
      fetchRegions(true);
    }, 500),
    []
  );

  const isNotGeneratedChildrenRow = (arrayChildrenRow: any) => {
    return arrayChildrenRow.some((id: any) => id.includes("placeholder-children"));
  };

  const handleCLickTag = (event: any, params: any) => {
    event.stopPropagation();
    if (params.row.numberOfRegulationTasks === 0) {
      return;
    }

    onOpenUpdateRegulationDialog(params.row, true);
  };

  const initialColumns = (columns: any) => {
    const initialColumnList: any = [];
    if (columns && columns.length > 0) {
      columns.forEach((column: any) => {
        if (column.type === "name") {
          initialColumnList.push({
            ...column,
            filterable: false,
            disableReorder: true,
            sortable: false,
            align: "left",
            valueGetter: (params: any) => params.row.name,
            cellClassName: "Klayo_associated-attribute-cell",
            renderCell: (params: any) => {
              const name = params?.row?.name;
              const isExpanded = params.rowNode.childrenExpanded;
              const paddingLeft = `${params.rowNode.depth * 24}px`;
              return (
                <div
                  style={{
                    fontWeight: isExpanded ? "bold" : "normal",
                    paddingLeft: paddingLeft
                  }}
                  className='truncate'
                >
                  <Tooltip title={name}>
                    <span>{name}</span>
                  </Tooltip>
                </div>
              );
            },
            flex: 1
          });
        } else if (column.type === "actions") {
          const nameColumn = {
            ...column,
            filterable: false,
            disableReorder: true,
            align: "right",
            valueGetter: (params: any) => (params.row.deleted ? null : params),
            getActions: (params: any) =>
              params.row.deleted
                ? []
                : [
                    <GridActionsCellItem
                      icon={<MoreVertIcon />}
                      label='Actions'
                      onClick={(event) => {
                        event.stopPropagation();
                        setSelectedRow(params.row);
                        setSelectedRowElement(event.currentTarget);
                      }}
                      key={Math.random().toString(36).substring(7)}
                    />
                  ]
          };
          initialColumnList.push(nameColumn);
        } else if (column.type === "task") {
          initialColumnList.push({
            ...column,
            filterable: false,
            disableReorder: true,
            sortable: false,
            align: "right",
            valueGetter: (params: any) => params.row.numberOfRegulationTasks,
            cellClassName: "Klayo_associated-attribute-cell",
            renderCell: (params: any) => (
              <KlayoTag
                isTag={
                  params.row.numberOfRegulationTasks !== 0 &&
                  params.row.type === REGULATION_ATTRIBUTE_TYPE.REGULATION
                }
                onClick={(event: any) => handleCLickTag(event, params)}
              >
                {params.row.numberOfRegulationTasks}
              </KlayoTag>
            )
          });
        }
      });
    }
    return initialColumnList;
  };

  const renderRowActionMenu = () => {
    if (!selectedRow) return null;

    if (selectedRow.type === REGULATION_ATTRIBUTE_TYPE.REGION) {
      return (
        <MenuItem
          onClick={() => {
            handleOnRowClick(selectedRow);
            setSelectedRowElement(null);
          }}
        >
          <ListItemText>Add Domain</ListItemText>
        </MenuItem>
      );
    }

    return (
      <div>
        <MenuItem
          onClick={() => {
            handleOnRowClick(selectedRow);
            setSelectedRowElement(null);
          }}
        >
          <ListItemText>Edit</ListItemText>
        </MenuItem>

        {selectedRow.type === REGULATION_ATTRIBUTE_TYPE.DOMAIN && (
          <MenuItem
            onClick={() => {
              onOpenCreateCategoryDialogFromRelatedDomain(selectedRow);
              setSelectedRowElement(null);
            }}
          >
            <ListItemText>Add Category</ListItemText>
          </MenuItem>
        )}

        {selectedRow.type === REGULATION_ATTRIBUTE_TYPE.CATEGORY && (
          <MenuItem
            onClick={() => {
              onOpenCreateRegulationFromRelatedCategory(selectedRow);
              setSelectedRowElement(null);
            }}
          >
            <ListItemText>Add Regulation</ListItemText>
          </MenuItem>
        )}

        <MenuItem onClick={onDeleteRow}>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </div>
    );
  };

  const onDeleteRow = () => {
    setSelectedRowElement(null);
    setDeletingRowError(null);
    setDeletingRowType(selectedRow.type);
    setIsDeleteRow(true);
  };

  const resetAllMessageError = () => {
    setRegulationDomainError(null);
    setRegulationCategoryError(null);
    setDeletingRowError(null);
  };

  const handleOnRowClick = (dataRow: any) => {
    switch (dataRow.type) {
      case REGULATION_ATTRIBUTE_TYPE.DOMAIN:
        onOpenUpdateDomainDialog(dataRow);
        break;

      case REGULATION_ATTRIBUTE_TYPE.CATEGORY:
        onOpenUpdateCategoryDialog(dataRow);
        break;

      case REGULATION_ATTRIBUTE_TYPE.REGULATION:
        onOpenUpdateRegulationDialog(dataRow);
        break;

      default:
        onOpenCreateDomainDialog(dataRow);
        break;
    }
  };

  const onOpenCreateRegulationFromRelatedCategory = (category: any) => {
    setIsShowUpdateRegulationDialog(true);
    setSelectedCategory(category);
  };

  const renderDomainAndCategoryActionMenu = () => {
    return (
      <div>
        <MenuItem onClick={() => onOpenCreateDomainDialog()}>
          <ListItemText>New domain</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onOpenCreateCategoryDialog()}>
          <ListItemText>New category</ListItemText>
        </MenuItem>
      </div>
    );
  };

  const onOpenCreateDomainDialog = (row?: any) => {
    setIsShowRegulationDomainDialog(true);
    setCreateRegulationDomainMode(CREATE_TASK_MODE.CREATE);
    setDomainAndCategoryActionMenuElement(null);
    resetAllMessageError();
    row && setSelectedRegion(row);
  };

  const onOpenUpdateDomainDialog = (domain: any) => {
    setIsShowRegulationDomainDialog(true);
    setCreateRegulationDomainMode(CREATE_TASK_MODE.EDIT);
    setSelectedDomain(domain);
    setDomainAndCategoryActionMenuElement(null);
    resetAllMessageError();
  };

  const onOpenCreateCategoryDialogFromRelatedDomain = (domain: any) => {
    setIsShowRegulationCategoryDialog(true);
    setCreateRegulationCategoryMode(CREATE_TASK_MODE.CREATE);
    setSelectedDomain(domain);
    setDomainAndCategoryActionMenuElement(null);
    resetAllMessageError();
  };

  const onOpenCreateCategoryDialog = () => {
    setIsShowRegulationCategoryDialog(true);
    setCreateRegulationCategoryMode(CREATE_TASK_MODE.CREATE);
    setDomainAndCategoryActionMenuElement(null);
    resetAllMessageError();
  };

  const onOpenUpdateCategoryDialog = (category: any) => {
    setIsShowRegulationCategoryDialog(true);
    setCreateRegulationCategoryMode(CREATE_TASK_MODE.EDIT);
    setSelectedCategory(category);
    setDomainAndCategoryActionMenuElement(null);
    resetAllMessageError();
  };

  const onCloseCreateDomainDialog = () => {
    setIsShowRegulationDomainDialog(false);
    setSelectedDomain(null);
    setSelectedRegion(null);
  };

  const onCloseCreateCategoryDialog = () => {
    setIsShowRegulationCategoryDialog(false);
    setSelectedDomain(null);
    setSelectedCategory(null);
    setSelectedDomain(null);
  };

  const onSaveRegulationDomain = async ({ domain, regionId, domainId }: any) => {
    const { onCreateRegulationDomain, onUpdateRegulationDomain } = props;

    let regulationDomain = null;

    try {
      setIsRegulationLoading(true);
      if (createRegulationDomainMode === CREATE_TASK_MODE.CREATE) {
        const res = await onCreateRegulationDomain(domain, regionId);
        setIsExistRegulationDomain(true);

        regulationDomain = res.regulationDomain;
      } else {
        const res = await onUpdateRegulationDomain(domain, domainId, regionId);

        regulationDomain = res.regulationDomain;
      }

      const row = {
        ...regulationDomain,
        hierarchy: [regulationDomain.regulationRegionId, regulationDomain.id],
        type: REGULATION_ATTRIBUTE_TYPE.DOMAIN,
        descendantCount: regulationDomain.numberOfCategories,
        regionRowId: regulationDomain.regulationRegionId
      };

      apiRef.current.updateRows([row]);

      onCloseCreateDomainDialog();
      setMessageSnackbar("Domain saved successfully");
    } catch (error) {
      setRegulationDomainError(ErrorModel.parseServerError(error));
    } finally {
      setIsRegulationLoading(false);
    }
  };

  const onSaveRegulationCategory = async ({ domain, category }: Record<string, string>) => {
    const { onCreateRegulationCategory, onUpdateRegulationCategory } = props;

    let regulationCategory = null;

    try {
      setIsCategoryLoading(true);
      if (createRegulationCategoryMode === CREATE_TASK_MODE.CREATE) {
        const res = await onCreateRegulationCategory(domain, category);

        regulationCategory = res.regulationCategory;
      } else {
        const res = await onUpdateRegulationCategory(category, selectedCategory.id, domain);

        regulationCategory = res.regulationCategory;
      }
      const row = {
        ...regulationCategory,
        hierarchy: [
          regulationCategory.regulationRegionId,
          regulationCategory.regulationDomainId,
          regulationCategory.id
        ],
        type: REGULATION_ATTRIBUTE_TYPE.CATEGORY,
        descendantCount: regulationCategory.numberOfRegulations,
        domainRowId: regulationCategory.regulationDomainId
      };

      apiRef.current.updateRows([row]);
      onCloseCreateCategoryDialog();
      setMessageSnackbar("Category saved successfully");
    } catch (error) {
      setRegulationCategoryError(ErrorModel.parseServerError(error));
    } finally {
      setIsCategoryLoading(false);
    }
  };

  const onCancelDeleteRegulation = () => {
    setIsDeleteRow(false);
    setDeletingRowType(null);
  };

  const onDeleteRegulationConfirmed = async () => {
    const { onDeleteDomain, onDeleteCategory, onDeleteRegulation } = props;

    Utils.deleteRow(apiRef, selectedRow.id, selectedRow.type);
    if (Utils.getAllRowIds(apiRef).length === 0) {
      setIsExistRegulationDomain(false);
    }

    try {
      setIsDeleteLoading(true);
      let successMessage = "";
      if (selectedRow.type === REGULATION_ATTRIBUTE_TYPE.DOMAIN) {
        await onDeleteDomain(selectedRow.id);
        successMessage = "Domain deleted successfully";
      } else if (selectedRow.type === REGULATION_ATTRIBUTE_TYPE.CATEGORY) {
        await onDeleteCategory(selectedRow.id);
        successMessage = "Category deleted successfully";
      } else {
        await onDeleteRegulation(selectedRow.id);
        successMessage = "Regulation deleted successfully";
      }
      setIsDeleteRow(false);
      setDeletingRowError(null);
      setDeletingRowType(null);
      setSelectedRowElement(null);
      setMessageSnackbar(successMessage);
    } catch (e) {
      setDeletingRowError(ErrorModel.parseServerError(e));
    } finally {
      setIsDeleteLoading(false);
    }
  };

  const onSearchValueChange = (event: any) => {
    event.preventDefault();
    setSearch(event.target.value);
    searchRef.current = event.target.value;
    searchDebounce(event.target.value);
  };

  const handleOnRowItemClick = (params: any, event: any) => {
    event.stopPropagation();
    handleOnRowClick(params.row);
    setSelectedRowElement(null);
  };

  const handleDeleteRowText = (selectedRow: any) => {
    const row = apiRef.current.getRow(selectedRow.id);

    if (deletingRowType === REGULATION_ATTRIBUTE_TYPE.DOMAIN) {
      if (row && row.descendantCount > 0) {
        return `Please delete all categories first.`;
      } else {
        return `Are you sure you want to delete this domain? This operation can't be undone.`;
      }
    } else if (deletingRowType === REGULATION_ATTRIBUTE_TYPE.CATEGORY) {
      return `Are you sure you want to delete this category? This action will also delete all regulations within it.`;
    } else {
      return `Are you sure you want to delete this regulation? This operation can't be undone.`;
    }
  };

  const onOpenUpdateRegulationDialog = (regulation: any, isTagClick?: boolean) => {
    setDomainAndCategoryActionMenuElement(null);
    resetAllMessageError();

    if (isTagClick) {
      return history.push(`/settings/regulations?regulationId=${regulation.id}&navigateStep=2`);
    }
    history.push(`/settings/regulations?regulationId=${regulation.id}`);
  };

  const onCloseUpdateRegulationDialog = () => {
    setIsShowUpdateRegulationDialog(false);
    setSelectedRegulation(null);
    setSelectedCategory(null);
    history.push(`/settings/regulations`);
  };

  const onSaveRegulationSuccess = (regulation: any) => {
    const updatedRow = {
      ...regulation,
      hierarchy: [
        regulation.regulationRegionId,
        regulation.regulationDomainId,
        regulation.regulationCategoryId,
        regulation.id
      ],
      type: REGULATION_ATTRIBUTE_TYPE.REGULATION,
      numberOfRegulationTasks: regulation.regulationTasks.length,
      categoryRowId: regulation.regulationCategoryId
    };
    apiRef.current.updateRows([updatedRow]);

    onCloseUpdateRegulationDialog();
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const regulationId = searchParams.get("regulationId");

    if (regulationId) {
      setSelectedRegulation({
        id: regulationId
      });

      setIsShowUpdateRegulationDialog(true);
    }
  }, [location.search]);

  return (
    <div>
      <Paper
        theme={theme}
        padding={{ xs: "46px 24px", md: "60px!important" }}
        borderFromBreakpoint='md'
      >
        <h1>Regulations</h1>
        <div>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)", padding: "30px 0" }}
          >
            <TextField
              value={search}
              dense={true}
              placeholder='Search'
              disabled={user === null}
              fullWidth={true}
              autoComplete={false}
              leadingIcon={<SearchIcon />}
              onChange={onSearchValueChange}
              sx={{ maxWidth: { md: "300px" } }}
            />

            <div
              className={`klaro_regulations-button-container ${!isExistedRegion.current ? "disabled-container" : ""}`}
            >
              <Button
                size='md'
                theme={theme}
                variant='filled'
                showLabelFromBreakpoint='md'
                startIcon={<AddIcon />}
                label='New regulation'
                disabled={!isExistedRegion.current}
                className='klaro_regulations-button-add-regulation'
                onClick={() => setIsShowUpdateRegulationDialog(true)}
              />
              <div className='klaro_regulations-vertical-line'></div>
              <div className='klaro_regulations-domain-category'>
                <ChevronDownWhiteIcon
                  onClick={(event: any) => {
                    if (isExistedRegion.current) {
                      setDomainAndCategoryActionMenuElement(event.currentTarget);
                    }
                  }}
                />
              </div>
            </div>
          </Stack>

          {/* No row component */}

          {loading ? (
            <SectionLoader />
          ) : !isExistedRegion.current ? (
            <TablePlaceholder
              text='Add your own + New regulation, or contact us to discuss importing your region’s regulations'
              icon={<AttributesIcon />}
            />
          ) : !getTableRows()?.length && searchRef.current ? (
            <TablePlaceholder text='No rows, yet' icon={<AttributesIcon />} />
          ) : null}

          {/* Table */}
          <RegulationsTable
            ref={apiRef}
            initialRows={regions}
            initialColumns={initialColumns}
            theme={theme}
            handleOnRowItemClick={handleOnRowItemClick}
            showHeader={false}
            hideFirstLastBorder={false}
            tableStyle={
              loading ? "klaro_regulations-display-none" : "klaro_regulations-display-block"
            }
          />
        </div>

        {isShowRegulationDomainDialog && (
          <CreateRegulationDomainDialog
            user={user}
            employee={user}
            theme={theme}
            onClose={onCloseCreateDomainDialog}
            onSave={onSaveRegulationDomain}
            error={regulationDomainError}
            mode={createRegulationDomainMode}
            selectedDomain={selectedDomain}
            selectedRegion={selectedRegion}
            loading={isRegulationLoading}
            onGetListRegulationRegion={onGetListRegulationRegion}
          />
        )}

        {isShowRegulationCategoryDialog && (
          <CreateRegulationCategoryDialog
            user={user}
            employee={user}
            theme={theme}
            onClose={onCloseCreateCategoryDialog}
            onSave={onSaveRegulationCategory}
            error={regulationCategoryError}
            mode={createRegulationCategoryMode}
            listRegulationDomain={listAllRegulationDomain}
            selectedCategory={selectedCategory}
            selectedDomain={selectedDomain}
            loading={isCategoryLoading}
            onGetListRegulationRegion={onGetListRegulationRegion}
          />
        )}
        {isShowUpdateRegulationDialog && (
          <UpdateRegulationDialog
            theme={theme}
            onClose={onCloseUpdateRegulationDialog}
            onSaveRegulation={onSaveRegulation}
            listRegulationDomain={listAllRegulationDomain}
            selectedRegulation={selectedRegulation}
            onGetListRegulationRegion={onGetListRegulationRegion}
            onGetListRegulationDomain={onGetListRegulationDomain}
            onGetListRegulationCategory={onGetListRegulationCategory}
            onUpdateRegulation={onUpdateRegulation}
            onSaveRegulationSuccess={onSaveRegulationSuccess}
            onBlockNavigation={onBlockNavigation}
            onAllowNavigation={onAllowNavigation}
            onNavigation={onNavigation}
            selectedCategory={selectedCategory}
          />
        )}

        {isDeleteRow && (
          <ConfirmationDialog
            theme={theme}
            title={`Delete ${
              selectedRow.type === REGULATION_ATTRIBUTE_TYPE.DOMAIN
                ? "domain"
                : selectedRow.type === REGULATION_ATTRIBUTE_TYPE.CATEGORY
                  ? "category"
                  : "regulation"
            }`}
            question={<div>{handleDeleteRowText(selectedRow)}</div>}
            acceptButton='Delete'
            acceptDanger={true}
            error={deletingRowError}
            onCancel={onCancelDeleteRegulation}
            onAccept={onDeleteRegulationConfirmed}
            isNotShowAcceptButton={
              selectedRow.type === REGULATION_ATTRIBUTE_TYPE.DOMAIN &&
              selectedRow.descendantCount > 0
            }
            loading={isDeleteLoading}
          />
        )}

        <StyledMenu
          open={domainAndCategoryActionMenuElement !== null}
          anchorEl={domainAndCategoryActionMenuElement}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          onClose={() => {
            setDomainAndCategoryActionMenuElement(null);
          }}
        >
          {renderDomainAndCategoryActionMenu()}
        </StyledMenu>

        <StyledMenu
          open={selectedRowElement !== null}
          anchorEl={selectedRowElement}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          onClose={() => setSelectedRowElement(null)}
        >
          {renderRowActionMenu()}
        </StyledMenu>

        {messageSnackbar && (
          <Snackbar
            open={true}
            duration={5000}
            onClose={() => setMessageSnackbar(null)}
            type='success'
            message={messageSnackbar}
          />
        )}
      </Paper>
    </div>
  );
}

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    margin-top: 10px !important;
  }
`;

const KlayoTag = styled.div<any>`
  padding: 2px 12px;
  background-color: ${(props) =>
    props.isTag ? KLAYO_COLORS.NeonBlueNB050 : KLAYO_COLORS.transparent};
  border-radius: 100px;

  color: ${(props) => (props.isTag ? KLAYO_COLORS.NeonBlueNB500 : KLAYO_COLORS.GreyGR900)};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  :hover {
    filter: ${(props) => (props.isTag ? "brightness(95%)" : "")};
    cursor: ${(props) => (props.isTag ? "pointer" : "")};
  }

  :focus {
    background-color: ${(props) => (props.isTag ? "#c6c7fc" : "")};
  }
`;
