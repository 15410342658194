import {
  Box,
  CircularProgress,
  Fade,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs
} from "@mui/material";
import { Delayed, EditTemplate } from "klayowebshared";
import { cloneDeep, get, trim } from "lodash";
import { AppContext } from "../../common/AppContext";
import { ErrorModel } from "../../common/models/ErrorModel";
import { Alert } from "../../components/Alert";
import { Button } from "../../components/Button";
import { MenuSelect } from "../../components/MenuSelect";
import { PaperOrDialog } from "../../components/PaperOrDialog";
import { TablePlaceholder } from "../../components/TablePlaceholder";
import { TextField } from "../../components/TextField";
import { TasksTable } from "../../components/table/TasksTable";
import { ReactComponent as AddIcon } from "../../resources/images/icons-add.svg";
import { ReactComponent as LeftIcon } from "../../resources/images/icons-arrow-left.svg";
import { ReactComponent as RightIcon } from "../../resources/images/icons-arrow-right.svg";
import { ReactComponent as CheckListIcon } from "../../resources/images/icons-checklist.svg";
import { ReactComponent as SearchIcon } from "../../resources/images/icons-search.svg";
import { TaskSelectorDialog } from "./TaskSelectorDialog";

import axiosClient from "../../common/AxiosClient";
import { ViewComponent } from "../../components/ViewComponent";
import { REGULATION_ATTRIBUTE_TYPE } from "../../constants";
import { trimToLowercase } from "../../utilities";
export class AddEditRegulationView extends ViewComponent {
  static contextType = AppContext;
  constructor(props: any) {
    super(props);

    this.state = {
      step: 0,
      saving: false,
      hasEdit: false,
      regulation: {
        name: null,
        region: null,
        domain: null,
        category: null,
        listSelectedTasks: []
      },
      error: null,
      search: null,
      listRegulationRegion: [],
      listDomain: [],
      listCategory: [],
      isShowTasksSelectDialog: false,
      taskSortModel: [
        {
          field: "name",
          sort: "asc"
        }
      ],
      existingRegulation: null
    };
  }

  async componentDidMount() {
    const { editMode, regulationId, navigateStep, selectedCategory } = this.props;

    if (navigateStep && navigateStep === "2") {
      this.setState({ step: 1 });
    }

    await this.getListRegulationRegion();

    if (editMode) {
      try {
        const response: any = await this.getRegulationDetails(regulationId);
        const { regulation } = response;

        await Promise.all([
          this.getListRegulationDomain(regulation.regulationRegionId),
          this.getListRegulationCategory(regulation.regulationDomainId)
        ]);

        this.setState({
          regulation: {
            name: regulation.name,
            region: regulation.regulationRegion.id,
            domain: regulation.regulationDomain.id,
            category: regulation.regulationCategory.id,
            listSelectedTasks: regulation.regulationTasks
          },
          existingRegulation: {
            name: regulation.name,
            region: regulation.regulationRegion.id,
            domain: regulation.regulationDomain.id,
            category: regulation.regulationCategory.id,
            listSelectedTasks: regulation.regulationTasks
          }
        });
      } catch (error) {
        // Handle error
      }
    }

    if (selectedCategory) {
      try {
        const regionId = selectedCategory.hierarchy[REGULATION_ATTRIBUTE_TYPE.REGION - 1];

        const regulation = {
          name: null,
          region: selectedCategory.hierarchy[REGULATION_ATTRIBUTE_TYPE.REGION - 1],
          domain: selectedCategory.domainRowId,
          category: selectedCategory.id,
          listSelectedTasks: []
        };

        await Promise.all([
          this.getListRegulationDomain(regionId),
          this.getListRegulationCategory(selectedCategory.domainRowId)
        ]);

        this.setState({
          regulation,
          existingRegulation: cloneDeep(regulation)
        });
      } catch (error) {
        // Handle error
      }
    }
  }

  async getRegulationDetails(regulationId: any) {
    return new Promise((resolve, reject) => {
      this.context.setLoading("loading", true);
      axiosClient
        .get(`/Regulation/${regulationId}`)
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((e: any) => {
          reject({ error: ErrorModel.parseServerError(e) });
        })
        .finally(() => {
          this.context.setLoading("loading", false);
        });
    });
  }

  onEditStateChange(hasEdit: any) {
    this.setState({ hasEdit });
  }

  onCancel(e: any, source: any) {
    if (source === "back") this.onNavigation(e, this.stopBlockingNavAndClose.bind(this));
    else this.stopBlockingNavAndClose(e);
  }

  stopBlockingNavAndClose(e: any) {
    const { onCancel, onBlockNavigation } = this.props;
    if (onBlockNavigation) onBlockNavigation(false, "Job");
    if (onCancel) onCancel(e);
  }

  onTabChange(e: any, newStep: any) {
    this.setState({ step: newStep });
  }

  onNameChange(e: any) {
    const { regulation } = this.state;
    regulation.name = e.target.value;
    this.setState({ regulation });
  }

  onBack() {
    this.setState({ step: 0 });
  }

  onNavigation(e: any, callback: any) {
    const { onNavigation } = this.props;
    return onNavigation && onNavigation(e, callback);
  }

  async onSave() {
    const { editMode, onBlockNavigation, onSave, existingRegulation } = this.props;
    const { regulation, step } = this.state;
    if (!editMode && step === 0 && this.isValid(regulation, existingRegulation, 0))
      this.setState({ step: 1 });

    if (editMode || step === 1) {
      if (onBlockNavigation) onBlockNavigation(false, "Regulation");
      this.setState({ saving: true });
      const params = {
        name: trim(regulation.name),
        regulationCategoryId: regulation.category,
        regulationTaskIds: regulation.listSelectedTasks.map((t: any) => t.id),
        regulationDomainId: regulation.domain,
        regulationRegionId: regulation.region
      };
      onSave(params);
    }
  }

  isValid(regulation: any, existingRegulation: any, step: any) {
    if (
      !regulation ||
      !this.isNameValid(regulation) ||
      !regulation.region ||
      !regulation.domain ||
      !regulation.category
    )
      return false;

    if (existingRegulation) {
      if (
        existingRegulation.name === regulation.name &&
        existingRegulation.region === regulation.region &&
        existingRegulation.domain === regulation.domain &&
        existingRegulation.category === regulation.category &&
        existingRegulation.listSelectedTasks === regulation.listSelectedTasks
      )
        return false;
    }

    if (step === 0) return true;
    if (step === 1 && regulation.listSelectedTasks && regulation.listSelectedTasks.length > 0)
      return true;
    return true;
  }

  isNameValid(regulation: any) {
    return regulation && regulation.name != null && regulation.name.length > 1;
  }

  handleValidationText(name: any) {
    if (!name || name.length < 2) return "Please enter a valid name (more than 1 character)";
    if (!name || name.length > 1000) return "Please enter a valid name (less than 1000 characters)";
  }

  onFormatListRegulationDomain(listRegulationDomain: any) {
    if (listRegulationDomain) {
      const newFormattedTypes = listRegulationDomain.reduce((acc: any, curr: any) => {
        acc[curr.id] = {
          id: curr.id,
          name: curr.name,
          value: curr.id,
          label: curr.name
        };
        return acc;
      }, {});
      return Object.values(newFormattedTypes);
    }
    return null;
  }

  onFormatListRegulationRegion(listRegulationRegion?: any) {
    if (listRegulationRegion) {
      const newFormattedTypes = listRegulationRegion.reduce((acc: any, curr: any) => {
        acc[curr.id] = {
          id: curr.id,
          name: curr.name,
          value: curr.id,
          label: curr.name
        };
        return acc;
      }, {});
      return Object.values(newFormattedTypes);
    }
    return null;
  }

  async onSelectRegionItem(event: any) {
    const { regulation } = this.state;
    regulation.region = event.target.value;
    regulation.domain = null;
    regulation.category = null;
    this.setState({ regulation: regulation });
    this.setState({ listDomain: [], listCategory: [] });

    if (!event.target.value) return;
    await this.getListRegulationDomain(event.target.value);
  }

  async onSelectDomainItem(event: any) {
    const { regulation } = this.state;
    regulation.domain = event.target.value;
    regulation.category = null;
    this.setState({ regulation: regulation });
    this.setState({ listCategory: [] });
    if (!event.target.value) return;
    await this.getListRegulationCategory(event.target.value);
  }

  onSelectCategoryItem(event: any) {
    const { regulation } = this.state;
    regulation.category = event.target.value;
    this.setState({ regulation: regulation });
  }

  async getListRegulationDomain(regionId: any) {
    const { onGetListRegulationDomain } = this.props;
    const listRegulationDomain = await onGetListRegulationDomain(regionId);
    const filterListDomainHaveCategory = listRegulationDomain.filter(
      (item: any) => item.numberOfCategories !== 0
    );
    this.setState({ listDomain: filterListDomainHaveCategory });
  }

  async getListRegulationRegion() {
    const { onGetListRegulationRegion } = this.props;
    const listRegulationRegion = await onGetListRegulationRegion();
    this.setState({ listRegulationRegion });
    return listRegulationRegion;
  }

  async getListRegulationCategory(domainId: any) {
    const { onGetListRegulationCategory } = this.props;
    const listRegulationCategory = await onGetListRegulationCategory(domainId);
    this.setState({ listCategory: listRegulationCategory });
  }

  onCloseTasksSelectDialog() {
    this.setState({ isShowTasksSelectDialog: false });
  }

  onShowTasksSelectDialog() {
    this.setState({ isShowTasksSelectDialog: true });
  }

  onSearch = (e: any) => {
    const searchValue = e.target.value;
    this.setState({ search: searchValue });
  };

  filterTasksInRegulationByName(tasks: any, search: any) {
    return tasks.filter((task: any) => task.name.toLowerCase().includes(search.toLowerCase()));
  }

  onTasksAdded(tasks: any) {
    const { regulation } = this.state;
    regulation.listSelectedTasks = [...tasks];
    this.setState(regulation);
    this.onCloseTasksSelectDialog();
  }

  onPositionAction(action: any, selectedRegulation: any) {
    const { regulation } = this.state;
    const filterRegulationList = regulation.listSelectedTasks.filter(
      (r: any) => r.id !== selectedRegulation.id
    );
    this.setState({ regulation: { ...regulation, listSelectedTasks: filterRegulationList } });
  }

  render() {
    const {
      theme,
      onAllowNavigation,
      onBlockNavigation,
      editMode,
      saveRegulationError,
      user,
      loading,
      isSaveLoading
    } = this.props;

    const {
      step,
      saving,
      hasEdit,
      regulation,
      error,
      search,
      listCategory,
      listDomain,
      isShowTasksSelectDialog,
      existingRegulation,
      listRegulationRegion
    } = this.state;

    const filterItems = [
      { id: "search", columnField: "name", operatorValue: "contains", value: search }
    ];

    const filterTasks = get(regulation, "listSelectedTasks", []).filter((task: any) => {
      if (search) {
        const keyword = trimToLowercase(search || "");
        const taskName = trimToLowercase(task?.name || "");
        return taskName?.includes(keyword);
      } else {
        return true;
      }
    });

    return (
      <EditTemplate
        theme={theme}
        name='regulation'
        onAllowNavigation={onAllowNavigation}
        onBlockNavigation={onBlockNavigation}
        onEditStateChange={this.onEditStateChange.bind(this)}
        detectEdit={!saving}
        compare={{
          existing: existingRegulation,
          editing: regulation,
          members: [
            { name: "name" },
            { name: "region" },
            { name: "domain" },
            { name: "category" },
            { name: "listSelectedTasks" }
          ]
        }}
      >
        <PaperOrDialog
          theme={theme}
          dialogToBreakpoint='md'
          disabledToBreakpoint='md'
          paperPadding='24px'
          title={editMode ? "Regulation details" : "Create regulation"}
          titleSx={{ fontSize: { xs: "20px", md: "30px" }, fontWeight: "bold" }}
          titleSize={1}
          onBack={this.onCancel.bind(this)}
          showActions={false}
        >
          <Stack direction='column' spacing={4}>
            {saveRegulationError && (
              <Alert severity='error' sx={{ width: "100%" }}>
                {saveRegulationError}
              </Alert>
            )}

            {!editMode ? (
              <Stepper activeStep={step}>
                <Step
                  onClick={this.onBack.bind(this)}
                  sx={{ cursor: step !== 0 ? "pointer" : "default" }}
                >
                  <StepLabel>Regulation information</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Tasks</StepLabel>
                </Step>
              </Stepper>
            ) : (
              <div>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    centered={true}
                    value={step}
                    onChange={this.onTabChange.bind(this)}
                    variant='fullWidth'
                    sx={{ width: "100%" }}
                  >
                    <Tab label='Regulation information' value={0} />
                    <Tab label='Tasks' value={1} />
                  </Tabs>
                </Box>
              </div>
            )}

            {step === 0 && (
              <Fade className='klayo__add-edit-employee-setting-container' in={true}>
                <Grid
                  container
                  sx={{
                    gap: 4
                  }}
                >
                  <Grid item xs={12}>
                    <TextField
                      label='Regulation name(required)'
                      placeholder=''
                      autoComplete={false}
                      autoFocus={true}
                      validationMethod={(e: any, value: any) => this.isNameValid(regulation)}
                      validationText={this.handleValidationText(regulation?.name)}
                      clearable={false}
                      sx={{ width: "100%" }}
                      value={regulation ? regulation.name : null}
                      onChange={this.onNameChange.bind(this)}
                      disabled={editMode && loading}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <MenuSelect
                      className='klayo_practical-type-select-menu'
                      label='Jurisdiction (required)'
                      value={regulation?.region || null}
                      firstValueDefault={false}
                      noSelectionError={true}
                      dense={true}
                      items={this.onFormatListRegulationRegion(listRegulationRegion)}
                      sx={{ width: "100%" }}
                      placeholder='Select jurisdiction'
                      clearable={false}
                      onChange={this.onSelectRegionItem.bind(this)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MenuSelect
                      className='klayo_practical-type-select-menu'
                      label='Domain (required)'
                      value={regulation?.domain || null}
                      firstValueDefault={false}
                      noSelectionError={true}
                      dense={true}
                      items={this.onFormatListRegulationDomain(listDomain)}
                      onChange={this.onSelectDomainItem.bind(this)}
                      sx={{ width: "100%" }}
                      disabled={listDomain.length === 0}
                      placeholder='Select domain'
                      clearable={false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MenuSelect
                      className='klayo_practical-type-select-menu'
                      label='Category (required)'
                      value={regulation?.category || null}
                      firstValueDefault={false}
                      noSelectionError={true}
                      dense={true}
                      items={this.onFormatListRegulationDomain(listCategory)}
                      onChange={this.onSelectCategoryItem.bind(this)}
                      sx={{ width: "100%" }}
                      disabled={listCategory.length === 0}
                      placeholder='Select category'
                      clearable={false}
                    />
                  </Grid>
                </Grid>
              </Fade>
            )}

            {step === 1 && (
              <Fade in={true}>
                <div>
                  <Stack direction='column' spacing={2}>
                    <div>
                      <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        spacing={2}
                      >
                        <TextField
                          value={search}
                          dense={true}
                          placeholder='Search task'
                          autoComplete={false}
                          leadingIcon={<SearchIcon />}
                          disabled={
                            get(regulation, "listSelectedTasks", []).length > 0
                              ? regulation.listSelectedTasks.length === 0
                              : true
                          }
                          onChange={this.onSearch.bind(this)}
                          sx={{ minWidth: "300px" }}
                        />

                        <Button
                          onClick={this.onShowTasksSelectDialog.bind(this)}
                          size='md'
                          variant='outlined'
                          startIcon={<AddIcon />}
                          label='Add tasks'
                        />
                      </Stack>

                      <Delayed>
                        <TasksTable
                          rows={filterTasks}
                          pagination={false}
                          theme={theme}
                          showFooter={false}
                          rowHasAction={true}
                          filterItems={filterItems}
                          hideFirstLastBorder={true}
                          noRowsComponent={
                            <TablePlaceholder
                              text='Use Add tasks button to link tasks for this regulation.'
                              icon={<CheckListIcon />}
                            />
                          }
                          dense={true}
                          paper={false}
                          columns={[
                            { type: TasksTable.columns.name },
                            { type: TasksTable.columns.actions }
                          ]}
                          onPositionAction={this.onPositionAction.bind(this)}
                          isUsingDeleteIcon={true}
                          onRowClick={() => {}}
                        />
                      </Delayed>
                    </div>
                  </Stack>
                </div>
              </Fade>
            )}

            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
              <Button onClick={this.onCancel.bind(this)} label='Cancel' disabled={isSaveLoading} />

              <Stack direction='row' spacing={2}>
                {step > 0 && !editMode && (
                  <Button
                    variant='outlined'
                    onClick={this.onBack.bind(this)}
                    startIcon={<LeftIcon />}
                    label='Back'
                    disabled={isSaveLoading}
                  />
                )}
                <Button
                  onClick={this.onSave.bind(this)}
                  variant='filled'
                  disabled={
                    isSaveLoading ||
                    !this.isValid(regulation, existingRegulation, step) ||
                    (editMode && !hasEdit)
                  }
                  endIcon={!editMode && step === 0 ? <RightIcon /> : null}
                  label={editMode ? "Save" : step === 0 ? "Continue" : "Create"}
                  startIcon={isSaveLoading && <CircularProgress size={20} />}
                  sx={{
                    display: "flex"
                  }}
                />
              </Stack>
            </Stack>
          </Stack>

          {isShowTasksSelectDialog && (
            <TaskSelectorDialog
              theme={theme}
              existingTasks={get(regulation, "listSelectedTasks", [])}
              onClose={this.onCloseTasksSelectDialog.bind(this)}
              onSave={this.onTasksAdded.bind(this)}
              subHeader='Select one or more tasks to be added to the regulation'
            />
          )}
        </PaperOrDialog>
      </EditTemplate>
    );
  }
}
