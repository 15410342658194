import styled from "@emotion/styled";
import { Tooltip } from "@mui/material";
import { COURSE_RECURRENCE } from "../../constants";
import { KLAYO_COLORS } from "../../themes";
import { SelectMenu } from "../SelectMenu";
import { Table } from "./Table";
import { ReactComponent as MoreVertIcon } from "../../resources/images/icons-more-vertical.svg";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";

export class TrainingTable extends Table {
  static columns = {
    courseName: {
      field: "courseName",
      label: "Training name"
    },
    provider: {
      field: "provider",
      label: "Provider"
    },
    faculty: {
      field: "faculties",
      label: "Faculty"
    },
    attributes: {
      field: "attributes",
      label: "Competencies"
    },
    recurrence: {
      field: "recurrence",
      label: "Recurrence"
    },
    actions: {
      field: "actions",
      label: "Actions"
    }
  };

  static defaultProps = {
    ...Table.defaultProps,
    columns: [
      { type: TrainingTable.columns.courseName },
      { type: TrainingTable.columns.faculty },
      { type: TrainingTable.columns.provider },
      { type: TrainingTable.columns.attributes },
      { type: TrainingTable.columns.recurrence },
      { type: TrainingTable.columns.actions }
    ],
    noRowsMessage: "No courses available",
    tree: true
  };

  constructor(props: any) {
    super(props);

    this.state = {
      ...this.state,
      rowHasAction: null,
      sortModel: this.props.sortModel
        ? this.props.sortModel
        : [
            {
              field: "courseName",
              sort: "asc"
            }
          ]
    };
  }

  getItemLabel(value: any) {
    const allItems: any = COURSE_RECURRENCE;

    for (const key in allItems) {
      if (allItems.hasOwnProperty(key)) {
        if (allItems[key].index === value) {
          return allItems[key];
        }
      }
    }

    return null; // Return null if the value is not found in the data
  }

  getRowId(e: any) {
    return this.tableId + "_" + e.rowId;
  }

  getTreeDataPath(row: any) {
    return row.hierarchy;
  }
  highlightText(text: string, keyword: string) {
    const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    return text.replace(new RegExp(escapedKeyword, "gi"), (match) => `<strong>${match}</strong>`);
  }
  initColumns() {
    const {
      columns,
      sortable,
      onRecurrentChange,
      showDialog,
      rowHasAction,
      rowNoActionIcon,
      keyword
    } = this.props;

    this.columns = [];

    columns.forEach((c: any) => {
      if (this.hasColumn(c)) {
        if (c.type === TrainingTable.columns.courseName)
          this.columns.push({
            field: "courseName",
            headerName: c.label || c.type.label,
            sortable: sortable,
            filterable: false,
            disableReorder: true,
            flex: 1,
            valueGetter: (params: any) => params.row.name,
            renderHeader: (params: any) => (
              <Tooltip
                classes={{ tooltip: "klayo__training-header-tooltip" }}
                title={
                  <div>
                    The name of the course comes <br /> from the integrated system
                  </div>
                }
                placement='top'
                arrow
              >
                <div className='MuiDataGrid-columnHeaderTitle'>{params.colDef.headerName}</div>
              </Tooltip>
            ),
            renderCell: (params: any) => {
              const isExpanded = params.rowNode.childrenExpanded;
              // Check if this is a child row
              const paddingLeft = `${params.rowNode.depth * 24}px`;
              return (
                <div
                  style={{
                    fontWeight: isExpanded ? "bold" : "normal",
                    paddingLeft: paddingLeft
                  }}
                  className='truncate'
                >
                  {/* <Tooltip title={params.row.name}>{params.row.name}</Tooltip> */}
                  <Tooltip title={params.row.name}>
                    <span>
                      {keyword ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.highlightText(params.row.name, keyword)
                          }}
                        />
                      ) : (
                        params.row.name
                      )}
                    </span>
                  </Tooltip>
                </div>
              );
            }
          });

        if (c.type === TrainingTable.columns.provider)
          this.columns.push({
            field: "provider",
            headerName: c.label || c.type.label,
            sortable: sortable,
            filterable: false,
            disableReorder: true,
            width: 120,
            headerAlign: "right",
            align: "right",
            valueGetter: (params: any) =>
              params.row?.organizationIntegrationAbbreviation
                ? params.row?.organizationIntegrationAbbreviation
                : params.row?.organizationIntegrationName
          });

        if (c.type === TrainingTable.columns.faculty)
          this.columns.push({
            field: "faculties",
            headerName: c.label || c.type.label,
            sortable: sortable,
            filterable: false,
            disableReorder: true,
            width: 120,
            headerAlign: "right",
            align: "right",
            valueGetter: (params: any) =>
              // params.row?.faculties ? params.row.faculties.map((f: any) => f.name).join(", ") : ""
              params.row?.facultiesAsString
          });

        if (c.type === TrainingTable.columns.attributes)
          this.columns.push({
            field: "attributes",
            headerName: c.label || c.type.label,
            sortable: sortable,
            filterable: false,
            disableReorder: true,
            width: 130,
            headerAlign: "right",
            align: "right",
            cellClassName: "Klayo_associated-attribute-cell",
            renderHeader: (params: any) => (
              <Tooltip
                classes={{ tooltip: "klayo__training-header-tooltip" }}
                title={<div>The number of competencies that are mapped to this course</div>}
                placement='top'
                arrow
              >
                <div className='MuiDataGrid-columnHeaderTitle'>{params.colDef.headerName}</div>
              </Tooltip>
            ),
            valueGetter: (params: any) => params.row.numberOfAssociatedAttributes,
            renderCell: (params: any) =>
              params.row.numberOfAssociatedAttributes == undefined ? null : (
                <KlayoTag
                  isTag={params.row.numberOfAssociatedAttributes !== 0}
                  onClick={() =>
                    params.row.numberOfAssociatedAttributes !== 0 ? showDialog(params.row) : {}
                  }
                >
                  {params.row.numberOfAssociatedAttributes}
                </KlayoTag>
              )
          });

        if (c.type === TrainingTable.columns.recurrence)
          this.columns.push({
            field: "recurrence",
            headerName: c.label || c.type.label,
            sortable: sortable,
            filterable: false,
            disableReorder: true,
            width: 160,
            headerAlign: "right",
            align: "right",
            cellClassName: "Klayo_recurrence-cell",
            renderHeader: (params: any) => (
              <Tooltip
                classes={{ tooltip: "klayo__training-header-tooltip" }}
                title={
                  <div>
                    When this course is completed, it must be re-done in this many months, and any
                    mapped competencies will have their expiry date automatically updated.
                  </div>
                }
                placement='top'
                arrow
              >
                <div className='MuiDataGrid-columnHeaderTitle'>{params.colDef.headerName}</div>
              </Tooltip>
            ),
            valueGetter: (params: any) => params.row.recurrence,
            renderCell: (params: any) =>
              params.row.recurrence == undefined ? null : (
                <SelectMenu
                  className='klayo-table_select'
                  menuClassName='Klayo_recurrence-menu-list'
                  clearable={false}
                  dense={true}
                  noSelectionError={true}
                  placeholder='Select'
                  value={this.getItemLabel(params.row.recurrence)}
                  items={Object.entries(COURSE_RECURRENCE)}
                  onChange={(e: any, value: any) => onRecurrentChange(params.row, e.target.value)}
                  sx={{ width: "100%" }}
                />
              )
          });
        if (c.type === TrainingTable.columns.actions)
          this.columns.push({
            field: "actions",
            type: "actions",
            filterable: false,
            align: "right",
            width: 50,
            disableReorder: true,
            renderCell: (params: any) => [
              !(params.rowNode.depth > 0) &&
              (rowHasAction === true || (rowHasAction && rowHasAction(params.row))) &&
              params.row.organizationIntegrationIntegrationSource === 3 ? (
                <GridActionsCellItem
                  icon={<MoreVertIcon />}
                  label='Actions'
                  onClick={(e) => this.props.onAttributeAction(e, params.row)}
                />
              ) : rowNoActionIcon ? (
                <Tooltip title='Under review' placement='right'>
                  {rowNoActionIcon}
                </Tooltip>
              ) : (
                <div></div>
              )
            ]
          });
      }
    });
  }
}

const KlayoTag = styled.div<any>`
  padding: 2px 12px;
  background-color: ${(props) =>
    props.isTag ? KLAYO_COLORS.NeonBlueNB050 : KLAYO_COLORS.transparent};
  border-radius: 100px;

  color: ${(props) => (props.isTag ? KLAYO_COLORS.NeonBlueNB500 : KLAYO_COLORS.GreyGR900)};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  :hover {
    filter: ${(props) => (props.isTag ? "brightness(95%)" : "")};
    cursor: ${(props) => (props.isTag ? "pointer" : "")};
  }

  :focus {
    background-color: ${(props) => (props.isTag ? "#c6c7fc" : "")};
  }
`;
