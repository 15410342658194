import { Autocomplete, ListItem, ListItemText, createFilterOptions } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import debounce from "lodash/debounce";
import { Component } from "react";
import { AppContext } from "../../common/AppContext";
import { ReactComponent as DropIcon } from "../../resources/images/icons-chevron-double.svg";
import { ReactComponent as RemoveIcon } from "../../resources/images/icons-remove.svg";
import { TextField } from "../TextField";

export class LocationFilter extends Component<any, any> {
  static contextType = AppContext;

  static defaultProps = {
    listType: "Filter",
    maxResults: 50,
    initialSearch: false,
    multiple: true,
    clearable: true,
    hasNoneItem: false,
    noneItemLabel: "None",
    hasAllItem: false,
    allItemLabel: "All",
    getItemId: null,
    debounce: true,
    clearOnSelection: false,
    clearResultsOnSelection: false,
    noOptionsText: "No search results",
    excludeItems: [],
    matchFrom: "any" //start or any
  };

  initialItems: any;
  value: any;
  hasTyped: boolean;
  filterOptions: any;
  debounceLoadItems: any;
  ignorePendingResults: boolean;
  dataList: any;

  constructor(props: any) {
    super(props);

    const allItems = this.filterItems(props.items || props.initialItems || []);

    this.state = {
      selectedValues: props.value ? this.findValue(allItems, props.value) : null,
      allValues: allItems,
      forceBlur: false
    };

    this.initialItems = props.initialItems;
    this.value = props.value;
    this.hasTyped = false;

    this.filterOptions = createFilterOptions({
      matchFrom: props.matchFrom,
      limit: props.maxResults,
      ignoreCase: true,
      stringify: (option) => this.getItemLabel(option)
    });

    this.debounceLoadItems = debounce((s) => this.loadItems(s), 500);
    this.ignorePendingResults = false;
  }

  onChange(e: any, selected: any) {
    const { onChange, clearOnSelection, clearResultsOnSelection } = this.props;

    if (clearOnSelection)
      this.setState(
        {
          selectedValues: null,
          forceBlur: true,
          ...(clearResultsOnSelection && { allValues: null })
        },
        () => {
          onChange(e, selected);
        }
      );
    else
      this.setState({ selectedValues: selected }, () => {
        onChange(e, selected);
      });
  }

  getItemLabel(item: any) {
    const { getItemLabel, hasNoneItem, noneItemLabel, hasAllItem, allItemLabel } = this.props;
    if (getItemLabel) return getItemLabel(item);

    if (hasNoneItem && item.isNoneItem === true) return noneItemLabel;
    else if (hasAllItem && item.isAllItem === true) return allItemLabel;
    return item.label;
  }

  clearResults() {
    const { items } = this.props;
    this.ignorePendingResults = true;
    this.setItems(this.initialItems ? this.initialItems : items ? items : []);
  }

  componentDidMount() {
    if (this.props.initialSearch) this.loadItems(null, true);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { allValues } = this.state;

    if (prevProps.initialItems !== this.props.initialItems) {
      this.initialItems = this.props.initialItems;
      this.setItems(this.props.initialItems);
    }
    if (prevProps.items !== this.props.items) {
      this.setItems(this.props.items);
    }
    if (prevProps.value !== this.props.value) {
      this.value = this.props.value;
      this.setState({ selectedValues: this.findValue(allValues, this.value) });
    }
  }

  findValue(items: any, value: any) {
    if (value && typeof value === "object") return value;
    return items?.find((item: any) => this.getItemId(item) === value) || null;
  }

  setItems(allValues: any) {
    const filteredItems = this.filterItems(allValues);
    this.setState({
      allValues: filteredItems
    });
  }

  filterItems(allValues: any) {
    const { filterItems } = this.props;
    if (filterItems) return filterItems(allValues);
    return allValues;
  }

  loadItems(value: any, initialSearch?: any) {
    const { callApi, listType } = this.props;

    if (callApi === false && initialSearch !== true) return;

    this.value = value;
    if (initialSearch !== true && (!value || value.length === 0)) return;

    this.context.setLoading("search" + listType, true);

    this.getData(value)
      .then((list: any) => {
        if (this.ignorePendingResults) this.ignorePendingResults = false;
        else {
          this.setItems(list);
        }
      })
      .finally(() => this.context.setLoading("search" + listType, false));
  }

  getData(value: any) {
    const { getData } = this.props;
    const customGetData = getData ? getData(value) : null;
    return customGetData ? customGetData : this.dataList.get(this.context, value);
  }

  filter(options: any) {
    const { maxResults } = this.props;
    return options.slice(0, maxResults);
  }

  renderOption(props: any, option: any, state: any) {
    const { getItemId } = this.props;

    const id = getItemId ? getItemId(option) : option.id;
    const name = this.getItemLabel(option);
    const matches = match(name, state.inputValue);
    const parts = parse(name, matches);

    return (
      <ListItem {...props} className='klayo-filterselector_option' key={id}>
        <ListItemText
          className='klayo-filterselector_option_text'
          primary={
            parts.length === 1 ? (
              name
            ) : (
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    className={
                      "klayo-selector_itempart" +
                      (part.highlight === true ? " klayo-selector_highlight" : "")
                    }
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            )
          }
        />
      </ListItem>
    );
  }

  getItemId(item: any) {
    const { getItemId } = this.props;
    return getItemId ? getItemId(item) : item;
  }

  render() {
    const {
      label,
      sx,
      placeholder,
      focusPlaceholder,
      multiple,
      clearable,
      disabled,
      loading,
      hasNoneItem,
      noneItemLabel,
      hasAllItem,
      allItemLabel,
      noOptionsText
    } = this.props;
    const { allValues, selectedValues, forceBlur } = this.state;

    const options = [];
    if (hasNoneItem) options.push({ id: -10, name: noneItemLabel, isNoneItem: true });
    if (hasAllItem) options.push({ id: -11, name: allItemLabel, isAllItem: true });

    if (allValues && allValues.length !== 0) options.push(...allValues);
    else if (this.initialItems) options.push(...this.initialItems);

    return (
      <Autocomplete
        className='klayo-filterselector klayo-selector'
        options={this.filter(options)}
        value={selectedValues ? selectedValues : null}
        getOptionLabel={this.getItemLabel.bind(this)}
        filterOptions={this.filterOptions}
        autoComplete
        includeInputInList
        disableClearable={!clearable}
        filterSelectedOptions
        popupIcon={<DropIcon />}
        clearIcon={<RemoveIcon />}
        handleHomeEndKeys
        noOptionsText={noOptionsText}
        multiple={multiple}
        renderOption={this.renderOption.bind(this)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            disabled={disabled}
            loading={loading}
            placeholder={placeholder || "Search"}
            focusPlaceholder={focusPlaceholder}
            forceBlur={forceBlur}
            sx={{ width: "100%" }}
            endAdornment={params.InputProps.endAdornment}
          />
        )}
        onChange={this.onChange.bind(this)}
        sx={sx}
      />
    );
  }
}
