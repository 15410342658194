export const PAGE_SIZE_FOR_ADD_EDIT = 15;
export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE = 0;
export const PAGE_SIZE_OPTIONS = [20, 50, 100];

export const PAGE_SIZE_FOR_NEW_PRACTICAL_ATTR = 5;

export const PAGE_SIZE_FOR_NEW_PRACTICAL_TASK = 5;

export const TableSearchDebounceTime = 500;

export const MyProfile_MyJob_ShowTasks_LocalStorageKey = "profile-job-competencies-show-task";

export const PARTICIPANT_MARKING = {
  None: {
    value: 0,
    label: "Not assessed"
  },
  Pass: {
    value: 1,
    label: "Satisfactory"
  },
  Failed: {
    value: 2,
    label: "Not yet satisfactory"
  }
};

export const IN_PROGRESS = "In progress";
export const PENDING_CONFIRMATION = "Pending confirmation";
export const COMPLETED = "Completed";

export const PRACTICAL_STATUS = {
  0: {
    value: 0,
    label: "In progress"
  },
  1: {
    value: 1,
    label: "Pending confirmation"
  },
  2: {
    value: 2,
    label: "Completed"
  }
};

export const PRACTICAL_TYPE = {
  NONE: 0,
  INSTRUCTOR: 1,
  PARTICIPANT: 2,
  BOTH: 3
};

export const INTEGRATION_STATUS = {
  ACTIVE: 1,
  PAUSE: 2,
  ISSUE: 3
};

export const TRAINING_FILTER_DEFAULT_VALUE = {
  ALL: {
    value: "all",
    label: "Show All Providers"
  }
};

export const TRAINING_FILTER_FACULTY_DEFAULT_VALUE = {
  ALL: {
    value: "all",
    label: "Show All Faculties"
  }
};

export const EMPLOYEE_FILTER_STATUS = {
  ALL: {
    value: "all",
    label: "Show All"
  },
  ADDED: {
    value: 0,
    label: "Added"
  },
  INVITED: {
    value: 1,
    label: "Invited"
  },
  ACTIVE: {
    value: 2,
    label: "Active"
  },
  INACTIVE: {
    value: 3,
    label: "Inactive"
  }
};

export const COURSE_RECURRENCE = {
  NONE: {
    index: 0,
    value: 0,
    label: "None",
    filterLabel: "None"
  },
  THREE_MONTHS: {
    index: 1,
    value: 1,
    label: "3 months",
    filterLabel: "3 months"
  },
  SIX_MONTHS: {
    index: 2,
    value: 2,
    label: "6 months",
    filterLabel: "6 months"
  },
  TWELVE_MONTHS: {
    index: 3,
    value: 3,
    label: "12 months",
    filterLabel: "12 months"
  },
  TWENTY_FOUR_MONTHS: {
    index: 4,
    value: 4,
    label: "24 months",
    filterLabel: "24 months"
  },
  THIRTY_SIX_MONTHS: {
    index: 5,
    value: 5,
    label: "36 months",
    filterLabel: "36 months"
  }
};

export const TRAINING_ATTRIBUTE_DIALOG_TYPE = {
  DETAIL: 1,
  WARNING: 2
};

export const TASK_ATTRIBUTE_DIALOG_TYPE = {
  COMPETENCIES: 1,
  REGULATIONS: 2,
  JOBS: 3
};

export const CREATE_TASK_MODE = {
  CREATE: 1,
  EDIT: 2
};
export const CREATE_PROVIDER_MODE = {
  CREATE: 1,
  EDIT: 2
};
export const CREATE_TRAINING_MODE = {
  CREATE: 1,
  EDIT: 2
};
export const REGULATION_ATTRIBUTE_TYPE = {
  REGION: 1,
  DOMAIN: 2,
  CATEGORY: 3,
  REGULATION: 4
};

export const Feedback_filters = {
  all: { key: "all", label: "All" },
  pending: { label: "Pending feedback" },
  submitted: { label: "Feedback submitted" }
};

export const Dashboard_mode = {
  personal: {
    name: "Personal",
    api: "/Dashboard",
    notificationsApi: "/Notifications"
  },
  team: {
    name: "Team",
    api: "/Dashboard/Teams",
    notificationsApi: "/Notifications/Teams"
  },
  groups: {
    name: "Groups",
    api: "/Dashboard/Groups",
    notificationsApi: "/Notifications/Group"
  }
};

export const Abstract_Attribute = {
  gapReason: {
    notSet: {
      label: "notSet"
    },
    met: {
      label: "met"
    },
    unmetMandatory: {
      label: "unmetMandatory"
    },
    unmet: {
      label: "unmet"
    }
  },
  status: {
    review: {
      index: 0,
      label: "Under review",
      longLabel: "Pending manager feedback",
      description: ""
    },
    feedback: {
      index: 1,
      label: "Needs feedback",
      longLabel: "Needs employee feedback",
      description: ""
    },
    approved: {
      index: 2,
      label: "Approved",
      longLabel: "Approved",
      description: ""
    },
    declined: {
      index: 3,
      label: "Declined",
      longLabel: "Declined",
      description: ""
    }
  },
  type: {
    mandatory: {
      index: 2,
      label: "Compliance",
      filterLabel: "Compliance"
    },
    required: {
      index: 1,
      label: "Required",
      filterLabel: "Required + Compliance"
    },
    desirable: {
      index: 0,
      label: "Desirable",
      filterLabel: "Desirable"
    }
  },
  compliance: {
    compliant: {
      label: "Compliant"
    },
    nonCompliant: {
      label: "Non-compliant"
    }
  }
};

export const Gap_Attribute = {
  reason: {
    ok: {
      label: "OK"
    },
    lowProficiency: {
      label: "Low proficiency"
    },
    missing: {
      label: "Missing"
    }
  }
};

export const DOMAIN_BLANK = { id: null, name: "<Blank>" };
export enum IntegrationSource {
  LearnUpon = 1,
  HAS_API_Enrolments = 2,
  Manual = 3
}
