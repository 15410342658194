import styled from "@emotion/styled";
import { Card, CardContent, IconButton, Menu, MenuItem, ListItemText } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Component } from "react";
import { ReactComponent as CertificateIcon } from "../resources/images/icons-certificate.svg";
import { KLAYO_COLORS } from "../themes";
import IntegrationAbbreviation from "../views/settings/IntegrationAbbreviation";
import { CompanyIntegrationStatus } from "./CompanyIntegrationStatus";
import { IntegrationSource } from "../constants";

export class CompanyIntegrationCard extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      menuAnchorEl: null
    };
  }

  handleMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
    const { onIntegrationAction } = this.props;
    event.stopPropagation();

    this.setState({ menuAnchorEl: event.currentTarget });

    onIntegrationAction(event, this.props.organizationIntegration);
  }

  handleMenuClose() {
    this.setState({ menuAnchorEl: null });
  }

  handleEditClick() {
    const { onEditIntegration } = this.props;
    this.handleMenuClose();

    onEditIntegration(this.props.organizationIntegration);
  }

  handleDeleteClick() {
    const { onDeleteIntegration } = this.props;

    this.handleMenuClose();

    onDeleteIntegration(this.props.organizationIntegration);
  }

  render() {
    const { organizationIntegration, organization } = this.props;
    const { name, description, status, lastSyncDate, isLiveSync, integrationSource } =
      organizationIntegration;
    const isManualProvider = integrationSource === IntegrationSource.Manual;
    const { menuAnchorEl } = this.state;

    return (
      <StyledCompanyIntegrationCard className='klayo_company-integration-card'>
        <CardContent>
          <div className='klayo_company-integration-content'>
            <CertificateIcon className='certification-icon' />
            <div className='klayo_company-integration-sub-content'>
              <p className='title'>
                {name}{" "}
                {organization &&
                  // organization.hasMultipleIntegrations &&
                  organizationIntegration.abbreviation && (
                    <IntegrationAbbreviation
                      data={organizationIntegration}
                      type={IntegrationAbbreviation.dataType.integration.value}
                    />
                  )}
              </p>
              <p className='description'>{description}</p>
            </div>
          </div>

          {isManualProvider ? (
            <>
              <IconButton className='more-options-button' onClick={this.handleMenuOpen.bind(this)}>
                <MoreVertIcon />
              </IconButton>

              <Menu
                open={Boolean(menuAnchorEl)}
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                onClose={this.handleMenuClose.bind(this)}
              >
                <MenuItem onClick={this.handleEditClick.bind(this)}>
                  <ListItemText>Edit provider</ListItemText>
                </MenuItem>
                <MenuItem onClick={this.handleDeleteClick.bind(this)}>
                  <ListItemText>Delete provider</ListItemText>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <CompanyIntegrationStatus
              status={status}
              lastSyncDate={lastSyncDate}
              isLiveSync={isLiveSync}
              {...this.props}
            />
          )}
        </CardContent>
      </StyledCompanyIntegrationCard>
    );
  }
}

const StyledCompanyIntegrationCard = styled(Card)`
  &.klayo_company-integration-card {
    width: 100%;
    border-radius: 16px;
    border: 1px solid ${KLAYO_COLORS.NeonBlueNB050};
    box-shadow: none;
    margin-top: 24px;

    .MuiCardContent-root {
      display: flex;
      justify-content: space-between;
      padding: 24px;
    }

    .klayo_company-integration-content {
      display: flex;
      gap: 24px;

      .certification-icon {
        color: ${KLAYO_COLORS.NeonBlueNB200};
      }
    }

    .klayo_company-integration-sub-content {
      .title {
        color: ${KLAYO_COLORS.GreyGR700};
        margin: 0px;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.1px;
      }

      .description {
        color: ${KLAYO_COLORS.GreyGR500};
        margin: 0px;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-right: 26px;
      }
    }

    .more-options-button {
      color: ${KLAYO_COLORS.GreyGR500};
    }
  }
`;
