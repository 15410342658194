import { CircularProgress, Stack } from "@mui/material";
import { Alert } from "klayowebshared";
import { Component } from "react";
import { AppContext } from "../../common/AppContext";
import { MenuSelect } from "../../components/MenuSelect";
import { TextField } from "../../components/TextField";
import { Dialog } from "../../components/dialogs/Dialog";
import { CREATE_TASK_MODE, REGULATION_ATTRIBUTE_TYPE } from "../../constants";
import { IDomain, IRegion } from "../../interfaces/Regulation";

interface IState {
  domain: string | null;
  region: string | null;
  category: string | null;
  regions: IRegion[];
  domains: IDomain[];
}

export class CreateRegulationCategoryDialog extends Component<any, IState> {
  static contextType = AppContext;

  constructor(props: any) {
    super(props);

    this.state = {
      domain: null,
      category: null,
      region: null,
      regions: [] as IRegion[],
      domains: [] as IDomain[]
    };
  }

  async componentDidMount() {
    this.setInitialState();

    await this.fetchRegions();
  }

  setInitialState() {
    const { mode, selectedDomain } = this.props;
    if (mode === CREATE_TASK_MODE.CREATE && selectedDomain) {
      this.setState({ domain: selectedDomain.id, region: selectedDomain.regionRowId });
    }

    if (mode === CREATE_TASK_MODE.EDIT) {
      const { selectedCategory } = this.props;

      this.setState({
        domain: selectedCategory.domainRowId,
        category: selectedCategory.name,
        region: selectedCategory.hierarchy[REGULATION_ATTRIBUTE_TYPE.REGION - 1]
      });
    }
  }

  async fetchRegions() {
    const { onGetListRegulationRegion } = this.props;

    const regions = await onGetListRegulationRegion();

    this.setState(
      {
        regions
      },
      () => {
        this.setDomains();
      }
    );
  }

  onFormatOptions(options: any) {
    if (options) {
      const newFormattedTypes = options.reduce((acc: any, curr: any) => {
        acc[curr.id] = {
          id: curr.id,
          name: curr.name,
          value: curr.id,
          label: curr.name
        };
        return acc;
      }, {});
      return Object.values(newFormattedTypes);
    }
    return null;
  }

  onSave() {
    const { onSave } = this.props;
    const { domain, category, region } = this.state;
    if (onSave) onSave({ domain, category, region });
  }

  onUpdateName(e: any) {
    this.setState({ category: e.target.value });
  }

  onSelectItem(event: any) {
    this.setState({ domain: event.target.value });
  }
  onSelectRegionItem(event: any) {
    const regionId = event.target.value;

    this.setState({ region: regionId }, () => {
      this.setDomains();
    });
  }

  setDomains() {
    const { regions, region: regionId } = this.state;

    if (!regionId) return;

    const selectedRegion = regions.find((region) => region.id === regionId);

    this.setState({ domains: selectedRegion?.regulationDomains || [] });
  }

  isDisabled() {
    const { domain, category, region } = this.state;

    const { loading, selectedCategory, mode } = this.props;
    return (
      !domain ||
      !category ||
      !region ||
      (mode === CREATE_TASK_MODE.EDIT &&
        category === selectedCategory.name &&
        domain === selectedCategory.domainRowId &&
        region === selectedCategory.hierarchy[REGULATION_ATTRIBUTE_TYPE.REGION - 1]) ||
      loading
    );
  }

  render() {
    const { user, theme, onClose, error, mode, selectedDomain, loading, selectedCategory } =
      this.props;
    const { domain, category, region, regions, domains } = this.state;

    return (
      <Dialog
        open={true}
        theme={theme}
        onClose={onClose}
        fullWidth={true}
        maxWidth='sm'
        title={mode === CREATE_TASK_MODE.EDIT ? "Edit category" : "Create category"}
        actions={[
          {
            label: `${mode === CREATE_TASK_MODE.EDIT ? "Save" : "Create"}`,
            primary: true,
            disabled: this.isDisabled.bind(this)(),
            onClick: this.onSave.bind(this),
            icon: loading && <CircularProgress size={20} />
          },
          {
            label: "Cancel",
            onClick: onClose,
            disabled: loading
          }
        ]}
      >
        <Stack direction='column' spacing={4}>
          {error && (
            <Alert severity='error' sx={{ width: "100%" }}>
              {error}
            </Alert>
          )}

          <MenuSelect
            className='klayo_practical-type-select-menu'
            label='Jurisdiction (required)'
            value={region}
            firstValueDefault={false}
            noSelectionError={true}
            dense={true}
            items={this.onFormatOptions(regions)}
            onChange={this.onSelectRegionItem.bind(this)}
            sx={{ width: "100%" }}
            placeholder='Select jurisdiction'
            clearable={false}
            disabled={!!selectedDomain || !!selectedCategory}
          />

          <MenuSelect
            className='klayo_practical-type-select-menu'
            label='Domain (required)'
            value={domain}
            firstValueDefault={false}
            noSelectionError={true}
            dense={true}
            items={this.onFormatOptions(domains)}
            onChange={this.onSelectItem.bind(this)}
            sx={{ width: "100%" }}
            placeholder='Select domain'
            clearable={false}
            disabled={!!selectedDomain || !!selectedCategory}
          />

          <TextField
            value={category}
            label='Category name (required)'
            dense={true}
            disabled={user === null}
            autoComplete={false}
            autoFocus={false}
            sx={{ width: "100%" }}
            onChange={this.onUpdateName.bind(this)}
          />
        </Stack>
      </Dialog>
    );
  }
}
