import { Grid, Stack } from "@mui/material";
import { Button } from "../components/Button";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { TRAINING_FILTER_DEFAULT_VALUE, TRAINING_FILTER_FACULTY_DEFAULT_VALUE } from "../constants";
import useDebounce from "../hooks/useDebounce";
import { ReactComponent as SearchIcon } from "../resources/images/icons-search.svg";
import { ReactComponent as AddIcon } from "../resources/images/icons-add.svg";
import { SelectMenu } from "./SelectMenu";
import { TextField } from "./TextField";

type Props = {
  onSearchChange: (search: string) => void;
  onFilterChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onFilterFacultyChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onCreateTraining: () => void;
  faculties: any;
  user: any;
  organization: any;
  integrations: any;
  theme?: any;
  onRefresh?: () => void;
  manualIntegrations: any;
};

const SettingTrainingFilter = ({
  onSearchChange,
  onFilterChange,
  onFilterFacultyChange,
  onCreateTraining,
  faculties,
  organization,
  user,
  integrations,
  theme,
  manualIntegrations
}: Props) => {
  const [search, setSearch] = useState("");
  const [integrationFilter, setIntegrationFilter] = useState(TRAINING_FILTER_DEFAULT_VALUE.ALL);
  const [facultyFilter, setFacultyFilter] = useState(TRAINING_FILTER_FACULTY_DEFAULT_VALUE.ALL);
  const isFirstRender = useRef(true);
  const debouncedSearch = useDebounce(search.trim(), 500);

  const onSelectChange = (e: any, type: string) => {
    if (type === "integration") {
      setIntegrationFilter(e.target.value);
      onFilterChange(e);
    } else {
      setFacultyFilter(e.target.value);
      onFilterFacultyChange(e);
    }
  };

  useEffect(() => {
    // Skip the first render
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // Only call the API on subsequent renders
    onSearchChange(debouncedSearch);
  }, [debouncedSearch]);

  return (
    <Stack
      direction='row'
      alignItems='center'
      spacing={2}
      sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)", padding: "40px 0px 24px 0px" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            value={search}
            dense={true}
            placeholder='Search course'
            disabled={user === null}
            fullWidth={true}
            autoComplete={false}
            leadingIcon={<SearchIcon />}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
            sx={{ maxWidth: { md: "300px" } }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            onClick={onCreateTraining}
            size='md'
            theme={theme}
            variant='filled'
            showLabelFromBreakpoint='md'
            startIcon={<AddIcon />}
            label='New training'
            disabled={!manualIntegrations || manualIntegrations.length === 0}
          />
        </Grid>
        <Grid item xs={3}>
          {organization?.hasMultipleIntegrations && (
            <SelectMenu
              className='employee-filter-section-menu'
              value={integrationFilter}
              firstValueDefault={true}
              dense={true}
              disabled={false}
              items={Object.entries(integrations)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSelectChange(e, "integration")}
              sx={{
                width: "100%"
              }}
            />
          )}
        </Grid>
        <Grid item xs={3}>
          {faculties && faculties.length > 0 && (
            <SelectMenu
              className='employee-filter-section-menu'
              value={facultyFilter}
              firstValueDefault={true}
              dense={true}
              disabled={false}
              items={Object.entries(faculties)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSelectChange(e, "faculty")}
              sx={{
                width: "100%"
              }}
            />
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default SettingTrainingFilter;
