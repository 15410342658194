import { Divider, ListItemText, Menu, MenuItem, Skeleton, Stack } from "@mui/material";
import { ConfirmationDialog, Switch } from "klayowebshared";
import { Component } from "react";
import { AppContext } from "../../common/AppContext";
import axiosClient from "../../common/AxiosClient";
import { ErrorModel } from "../../common/models/ErrorModel";
import { Button } from "../../components/Button";
import { CompanyIntegrationCard } from "../../components/CompanyIntegrationCard";
import { Paper } from "../../components/Paper";
import { Snackbar } from "../../components/Snackbar";
import { AddressTable } from "../../components/table/AddressTable";
import { LocationTable } from "../../components/table/LocationTable";
import { TooltipIcon } from "../../components/TooltipIcon";
import { Organization } from "../../data/Organization";
import { ReactComponent as AddIcon } from "../../resources/images/icons-add.svg";
import { ReactComponent as EditIcon } from "../../resources/images/icons-edit.svg";
import { AddEditAddressDialog } from "./AddEditAddressDialog";
import { AddEditLocationDialog } from "./AddEditLocationDialog";
import { CompanyNameDialog } from "./CompanyNameDialog";
import CreateProviderDialog from "./CreateProviderDialog";
import { CREATE_PROVIDER_MODE } from "../../constants";
import {
  Provider,
  ProviderApiResponse,
  CreateProviderRequest,
  UpdateProviderRequest
} from "../../interfaces/ProviderTraining";

export class SettingsCompanyView extends Component<any, any> {
  static contextType = AppContext;
  static menuType = {
    address: 0,
    location: 1,
    integration: 2
  };

  constructor(props: any) {
    super(props);

    this.state = {
      addLocationError: null,
      deleteLocationError: null,
      deleteAddressError: null,
      savingLocation: false,
      actionTarget: null,
      actionLocation: null,
      actionProvider: null,
      menuType: null,
      deletingLocation: null,
      deletingProvider: null,
      deletingAddress: null,
      hasResetDemoData: false,
      successMessage: null,
      enableJobMatching: false,
      allowJobMatchingConfig: false,
      showCreateProviderDialog: false,
      providerDialogMode: CREATE_PROVIDER_MODE.CREATE,
      editingProvider: null,
      savingProvider: false,
      addProviderError: null,
      deleteProviderError: null,
      showProviderTrainingWarning: false
    };
  }
  componentDidMount() {
    this.setState({
      enableJobMatching: this.props.orgSettings?.enableJobMatching,
      allowJobMatchingConfig: this.props.orgSettings?.allowJobMatchingConfig
    });
  }
  //  Add provider
  onShowCreateTaskDialog() {
    this.setState({
      showCreateProviderDialog: true,
      providerDialogMode: CREATE_PROVIDER_MODE.CREATE,
      editingProvider: null
    });
  }
  onShowEditProviderDialog(provider: any) {
    this.setState({
      showCreateProviderDialog: true,
      providerDialogMode: CREATE_PROVIDER_MODE.EDIT,
      editingProvider: provider
    });
  }
  onCloseCreateTaskDialog() {
    this.setState({
      showCreateProviderDialog: false,
      editingProvider: null,
      addProviderError: null
    });
  }
  onCreateProvider(longName: string, shortName: string) {
    const { orgSettings } = this.props;

    this.setState({ savingProvider: true });

    axiosClient
      .post<CreateProviderRequest>("/Integration", {
        name: longName,
        abbreviation: shortName
      })
      .then((response) => {
        // Add the new provider to orgSettings
        if (orgSettings && orgSettings.organizationIntegrations && response.data) {
          const newProvider = response.data.integration;
          orgSettings.addProvider(newProvider);
        }

        this.setState({
          showCreateProviderDialog: false,
          successMessage: "Provider added successfully"
        });
      })
      .catch((e) => {
        this.setState({
          addProviderError: ErrorModel.parseServerError(e)
        });
      })
      .finally(() => {
        this.setState({ savingProvider: false });
      });
  }

  onUpdateProvider(longName: string, shortName: string, providerId: string) {
    const { orgSettings } = this.props;

    this.setState({ savingProvider: true });

    return axiosClient
      .put<UpdateProviderRequest>("/Integration", {
        id: providerId,
        name: longName,
        abbreviation: shortName
      })
      .then((response) => {
        // Update the provider in orgSettings
        if (orgSettings && orgSettings.organizationIntegrations) {
          const updatedProvider = {
            id: response.data.id,
            name: response.data.name,
            abbreviation: response.data.abbreviation,
            integrationSource: response.data.integrationSource
          };

          // Update the provider in the state
          orgSettings.updateProvider(updatedProvider);
        }

        this.setState({
          showCreateProviderDialog: false,
          editingProvider: null,
          successMessage: "Provider updated successfully"
        });
      })
      .catch((e) => {
        this.setState({
          addProviderError: ErrorModel.parseServerError(e)
        });
      })
      .finally(() => {
        this.setState({ savingProvider: false });
      });
  }
  onSaveProvider(longName: string, shortName: string, providerId = null) {
    if (providerId) {
      return this.onUpdateProvider(longName, shortName, providerId);
    }
    return this.onCreateProvider(longName, shortName);
  }
  onIntegrationAction(e: any, integration: Provider) {
    this.setState({
      actionProvider: integration
    });
  }
  onEditIntegration() {
    const { actionProvider } = this.state;
    this.onCloseMenu();

    // Show the edit dialog
    this.onShowEditProviderDialog(actionProvider);
  }
  onDeleteIntegration() {
    const { actionProvider } = this.state;
    this.onCloseMenu();

    if (actionProvider.hasTraining) {
      this.setState({
        deletingProvider: actionProvider,
        showProviderTrainingWarning: true
      });
    } else {
      this.deleteProvider(actionProvider);
    }
  }
  onDeleteProviderConfirmed() {
    const { deletingProvider } = this.state;
    this.deleteProvider(deletingProvider);
  }
  onCancelDeleteProvider() {
    this.setState({
      deletingProvider: null,
      deleteProviderError: null,
      showProviderTrainingWarning: false
    });
  }
  deleteProvider(provider: any) {
    const { orgSettings } = this.props;

    axiosClient
      .delete(`/Integration/${provider.id}`)
      .then((response: any) => {
        // Remove the provider from orgSettings
        if (orgSettings && orgSettings.organizationIntegrations) {
          orgSettings.deleteProvider(provider);
        }

        this.setState({
          deletingProvider: null,
          deleteProviderError: null,
          showProviderTrainingWarning: false,
          successMessage: "Provider deleted successfully"
        });
        this.forceUpdate();
      })
      .catch((e: any) => {
        this.setState({
          deletingProvider: provider,
          deleteProviderError: ErrorModel.parseServerError(e)
        });
      })
      .finally(() => {});
  }
  // New method to handle job matching toggle
  onToggleJobMatching = async (enabled: boolean) => {
    try {
      this.setState({
        enableJobMatching: enabled
      });

      this.context.setLoading("jobMatching", true);

      await axiosClient.put("/Organization/Admin/Settings", {
        organizationId: this.props.organization.id,
        enableJobMatching: enabled
      });

      this.context.organization.enableJobMatching = enabled;

      this.props.onLoadOrgSettings(false);

      this.setState({
        successMessage: `Job matching ${enabled ? "enabled" : "disabled"} successfully`
      });
    } catch (e) {
      this.setState({
        successMessage: null,
        error: ErrorModel.parseServerError(e)
      });
      this.setState({
        enableJobMatching: !enabled
      });
    } finally {
      this.context.setLoading("jobMatching", false);
    }
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    // window.scroll({ top: 0, left: 0, behavior: "smooth" });
    const prevMessage = prevProps?.location?.state?.successMessage;
    const currentMessage = this.props?.location?.state?.successMessage;
    if (prevMessage !== currentMessage && !!currentMessage) {
      this.setState({ successMessage: currentMessage });
    }

    if (
      prevProps.orgSettings?.allowJobMatchingConfig !==
      this.props.orgSettings?.allowJobMatchingConfig
    ) {
      this.setState({
        allowJobMatchingConfig: this.props.orgSettings?.allowJobMatchingConfig
      });
    }
    if (prevProps.orgSettings?.enableJobMatching !== this.props.orgSettings?.enableJobMatching) {
      this.setState({
        enableJobMatching: this.props.orgSettings?.enableJobMatching
      });
    }
  }

  onCloseCompanyNameDialog() {
    this.props.history.push("/settings/company");
  }

  onCloseAddEditLocationDialog() {
    this.props.history.push("/settings/company");
    this.setState({ addLocationError: null });
  }

  onCloseAddEditAddressDialog() {
    this.props.history.push("/settings/company");
    this.setState({ addAddressError: null });
  }

  onCompanyNameChange(name: any) {
    const { organization, onCompanyNameChange } = this.props;
    organization.name = name;
    this.props.history.push("/settings/company");
    if (onCompanyNameChange) onCompanyNameChange(name);
  }

  onResetDemoData() {
    const { user } = this.state;

    this.context.setLoading("resetDemoData", true);

    axiosClient
      .put("/Organization/ResetDemo", null, {
        withCredentials: true
      })
      .then((response: any) => {
        this.setState({ hasResetDemoData: true });
        window.location.reload();
      })
      .finally(() => {
        this.context.setLoading("resetDemoData", false);
      });
  }

  onLocationAction(e: any, location: any) {
    this.setState({
      actionTarget: e.target,
      actionLocation: location,
      menuType: SettingsCompanyView.menuType.location
    });
  }

  onAddressAction(e: any, location: any) {
    this.setState({
      actionTarget: e.target,
      actionLocation: location,
      menuType: SettingsCompanyView.menuType.address
    });
  }

  onSaveLocation(locationId: any, name: any) {
    const { orgSettings } = this.props;

    this.context.setLoading("saving", true);
    this.setState({ savingLocation: true });

    if (locationId) {
      axiosClient
        .put("/Organization/Admin/Location", {
          locationId,
          name
        })
        .then((response: any) => {
          orgSettings.updateLocation(response.data.location);
          this.onCloseAddEditLocationDialog();
        })
        .catch((e: any) => {
          this.setState({ addLocationError: ErrorModel.parseServerError(e) });
        })
        .finally(() => {
          this.context.setLoading("saving", false);
          this.setState({ savingLocation: false });
        });
    } else {
      axiosClient
        .post("/Organization/Admin/Location", {
          name: name
        })
        .then((response: any) => {
          orgSettings.addLocation(response.data.location);
          this.onCloseAddEditLocationDialog();
        })
        .catch((e: any) => {
          this.setState({ addLocationError: ErrorModel.parseServerError(e) });
        })
        .finally(() => {
          this.context.setLoading("saving", false);
          this.setState({ savingLocation: false });
        });
    }
  }

  onEditAddress() {
    this.onCloseMenu();

    this.props.history.push("/settings/company/address/edit/");
  }

  deleteAddress() {
    const { orgSettings } = this.props;
    this.context.setLoading("deleting", true);

    axiosClient
      .delete("/Organization/Admin/Address", {
        data: {},
        withCredentials: true
      })
      .then((response: any) => {
        orgSettings.deleteAddress();
        this.setState({
          deletingAddress: null,
          deleteAddressError: null,
          successMessage: "Delete address successfully"
        });
        this.forceUpdate();
      })
      .catch((e: any) => {
        this.setState({
          deletingAddress: true,
          deleteAddressError: ErrorModel.parseServerError(e)
        });
      })
      .finally(() => {
        this.context.setLoading("deleting", false);
      });
  }

  onDeleteAddress() {
    this.setState({ deletingAddress: true });
    this.onCloseMenu();
  }

  onDeleteAddressConfirmed() {
    this.deleteAddress();
    this.setState({ deletingAddress: null });
  }

  onCancelDeleteAddress() {
    this.setState({ deletingAddress: null, deleteAddressError: null });
  }

  onEditLocation() {
    const { actionLocation } = this.state;
    this.onCloseMenu();

    this.props.history.push("/settings/company/locations/edit/" + actionLocation.locationId);
  }

  onDeleteLocation() {
    const { actionLocation } = this.state;

    if (actionLocation.isInUsed) this.setState({ deletingLocation: actionLocation });
    else this.deleteLocation(actionLocation);

    this.onCloseMenu();
  }

  onDeleteLocationConfirmed() {
    const { deletingLocation } = this.state;
    this.deleteLocation(deletingLocation);
    // this.setState({ deletingLocation: null });
  }

  onCancelDeleteLocation() {
    this.setState({ deletingLocation: null, deleteLocationError: null });
  }

  deleteLocation(location: any) {
    const { orgSettings } = this.props;
    this.context.setLoading("deleting", true);

    axiosClient
      .delete("/Organization/Admin/Location", {
        data: { locationId: location.locationId }
      })
      .then((response: any) => {
        orgSettings.deleteLocation(location);
        this.setState({ deletingLocation: null, deleteLocationError: null });
        this.forceUpdate();
      })
      .catch((e: any) => {
        this.setState({
          deletingLocation: location,
          deleteLocationError: ErrorModel.parseServerError(e)
        });
      })
      .finally(() => {
        this.context.setLoading("deleting", false);
      });
  }

  onCloseMenu() {
    this.setState({ actionTarget: null, actionLocation: null, menuType: null });
  }

  onSnackBarClose() {
    this.setState({ successMessage: null });
  }

  render() {
    const { organization, orgSettings, theme, user, location } = this.props;
    const {
      menuType,
      savingLocation,
      addLocationError,
      actionTarget,
      deletingAddress,
      deletingLocation,
      hasResetDemoData,
      deleteLocationError,
      deleteAddressError,
      successMessage,
      enableJobMatching,
      allowJobMatchingConfig,
      showCreateProviderDialog,
      providerDialogMode,
      editingProvider,
      showProviderTrainingWarning
    } = this.state;
    const path = location.pathname;
    const showCompanyNameDialog = path.startsWith("/settings/company/edit");
    const editingLocation = path.startsWith("/settings/company/locations/edit/");
    const showAddEditLocationDialog =
      path.startsWith("/settings/company/locations/new") || editingLocation;
    const editingAddress = path.startsWith("/settings/company/address/edit/");
    const showAddEditAddressDialog =
      path.startsWith("/settings/company/address/new") || editingAddress;

    let editLocation = null;

    if (orgSettings && editingLocation) {
      const editLocationId = path.replace("/settings/company/locations/edit/", "");
      editLocation = orgSettings.getLocation(editLocationId);
    }

    return (
      <div>
        <Paper
          theme={theme}
          padding={{ xs: "40px 24px", md: "56px 64px" }}
          borderFromBreakpoint='md'
        >
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
            <h1 style={{ margin: 0 }}>{orgSettings ? orgSettings.name : <Skeleton />}</h1>
            <Button
              path={"/settings/company/edit"}
              disabled={false}
              icon={<EditIcon width='20px' height='20px' />}
            />
          </Stack>

          <Divider sx={{ margin: "40px 0" }} />
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
            <div>
              <h2>Address</h2>
              <div className='klayo-instruction'>Manage your company address</div>
            </div>
            {orgSettings && !orgSettings.address && (
              <Button
                path={"/settings/company/address/new"}
                size='md'
                theme={theme}
                startIcon={<AddIcon className='klayo-add-icon' />}
                showLabelFromBreakpoint='md'
                label='Add address'
              />
            )}
          </Stack>
          {orgSettings && orgSettings.address && (
            <AddressTable
              sx={{ marginTop: "32px" }}
              theme={theme}
              showHeader={false}
              pagination={false}
              showFooter={false}
              minHeight={
                orgSettings && orgSettings.address && orgSettings.address.length !== 0
                  ? ""
                  : "300px"
              }
              hideFirstLastBorder={true}
              dense={true}
              onAddressAction={this.onAddressAction.bind(this)}
              paper={false}
              rows={orgSettings && orgSettings.address ? [orgSettings.address] : null}
            />
          )}

          <Divider sx={{ margin: "40px 0" }} />
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
            <div>
              <h2>Locations</h2>
              <div className='klayo-instruction'>Manage your office locations</div>
            </div>
            <Button
              path={"/settings/company/locations/new"}
              size='md'
              theme={theme}
              startIcon={<AddIcon className='klayo-add-icon' />}
              showLabelFromBreakpoint='md'
              label='Add location'
            />
          </Stack>
          {orgSettings && orgSettings.locations && orgSettings.locations.length !== 0 && (
            <LocationTable
              sx={{ marginTop: "32px" }}
              theme={theme}
              showHeader={false}
              pagination={false}
              showFooter={false}
              minHeight={
                orgSettings && orgSettings.locations && orgSettings.locations.length !== 0
                  ? ""
                  : "300px"
              }
              hideFirstLastBorder={true}
              dense={true}
              onLocationAction={this.onLocationAction.bind(this)}
              paper={false}
              rows={orgSettings ? orgSettings.locations : null}
            />
          )}

          {actionTarget && (
            <Menu
              open={true}
              anchorEl={actionTarget}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              onClose={this.onCloseMenu.bind(this)}
            >
              {menuType === SettingsCompanyView.menuType.location ? (
                <>
                  <MenuItem onClick={this.onEditLocation.bind(this)}>
                    <ListItemText>Edit location</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={this.onDeleteLocation.bind(this)}>
                    <ListItemText>Delete location</ListItemText>
                  </MenuItem>
                </>
              ) : menuType === SettingsCompanyView.menuType.address ? (
                <>
                  <MenuItem onClick={this.onEditAddress.bind(this)}>
                    <ListItemText>Edit address</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={this.onDeleteAddress.bind(this)}>
                    <ListItemText>Delete address</ListItemText>
                  </MenuItem>
                </>
              ) : null}
            </Menu>
          )}

          <Divider sx={{ margin: "40px 0" }} />
          <div style={{ marginBottom: "40px" }}>
            <h2>Proficiency</h2>
            <div className='klayo-instruction'>Manage your competency proficiency settings</div>
          </div>
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
            <Switch
              label={
                "Proficiency " +
                (orgSettings && orgSettings.showProficiency ? "enabled" : "disabled")
              }
              checked={orgSettings && orgSettings.showProficiency}
              disabled={true}
            />
            <TooltipIcon
              text={
                "Contact us to discuss turning proficiency " +
                (orgSettings && orgSettings.showProficiency ? "off" : "on")
              }
            />
          </Stack>

          <Divider sx={{ margin: "40px 0" }} />
          <div style={{ marginBottom: "40px" }}>
            <h2>Job matching</h2>
            <div className='klayo-instruction'>Manage the job matching feature in My profile</div>
          </div>
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
            <Switch
              label={`Job matching ${enableJobMatching ? "enabled" : "disabled"}`}
              disabled={!(allowJobMatchingConfig || enableJobMatching)}
              checked={enableJobMatching}
              onChange={(e: any) => this.onToggleJobMatching(e.target.checked)}
            />
            {!(allowJobMatchingConfig || enableJobMatching) && (
              <TooltipIcon text={"Contact us to discuss turning job matching on"} />
            )}
          </Stack>

          {
            <div>
              <Divider sx={{ margin: "40px 0" }} />
              <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                <div>
                  <h2>Training providers</h2>
                  <div className='klayo-instruction'>Manage your company's training providers</div>
                </div>
                <Button
                  size='md'
                  theme={theme}
                  startIcon={<AddIcon className='klayo-add-icon' />}
                  showLabelFromBreakpoint='md'
                  label='Add provider'
                  onClick={this.onShowCreateTaskDialog.bind(this)}
                />
              </Stack>
              {orgSettings &&
                orgSettings.organizationIntegrations &&
                orgSettings.organizationIntegrations.length !== 0 && (
                  <Stack direction='column' justifyContent='space-between' alignItems='center'>
                    {orgSettings.organizationIntegrations.map((integrationItem: any) => (
                      <CompanyIntegrationCard
                        key={integrationItem.id}
                        organization={organization ? organization : null}
                        organizationIntegration={integrationItem}
                        onIntegrationAction={this.onIntegrationAction.bind(this)}
                        onEditIntegration={this.onEditIntegration.bind(this)}
                        onDeleteIntegration={this.onDeleteIntegration.bind(this)}
                      />
                    ))}
                  </Stack>
                )}
            </div>
          }

          {organization && organization.isDemo && (
            <div>
              <Divider sx={{ margin: "40px 0" }} />
              <div>
                <h2>Reset demo data</h2>
                <div className='klayo-instruction'>
                  Reset all demo company data to its original state
                </div>
              </div>
              <Button
                label='Reset data'
                variant='filled'
                disabled={
                  hasResetDemoData || organization.demoOrgStatus === Organization.status.resetting
                }
                onClick={this.onResetDemoData.bind(this)}
              />
            </div>
          )}
        </Paper>

        {showCompanyNameDialog && (
          <CompanyNameDialog
            organization={organization}
            theme={theme}
            onSave={this.onCompanyNameChange.bind(this)}
            onClose={this.onCloseCompanyNameDialog.bind(this)}
          />
        )}

        {showAddEditLocationDialog && (
          <AddEditLocationDialog
            theme={theme}
            saving={savingLocation}
            location={editLocation}
            onSave={this.onSaveLocation.bind(this)}
            error={addLocationError}
            onClose={this.onCloseAddEditLocationDialog.bind(this)}
          />
        )}

        {showAddEditAddressDialog && (
          <AddEditAddressDialog
            theme={theme}
            saving={savingLocation}
            isEdit={editingAddress}
            orgSettings={orgSettings}
            error={addLocationError}
            onClose={this.onCloseAddEditAddressDialog.bind(this)}
            address={orgSettings?.address ? orgSettings?.address : null}
            {...this.props}
          />
        )}

        {deletingLocation && (
          <ConfirmationDialog
            theme={theme}
            title='Delete?'
            question='This location is currently used by employees. Are you sure you want to delete this location?'
            cancelButton='No'
            acceptButton='Yes'
            acceptDanger={true}
            error={deleteLocationError}
            onCancel={this.onCancelDeleteLocation.bind(this)}
            onAccept={this.onDeleteLocationConfirmed.bind(this)}
          />
        )}

        {deletingAddress && (
          <ConfirmationDialog
            theme={theme}
            title='Delete address'
            question='Are you sure you want to delete the company address? Some features may not work without a physical address.'
            cancelButton='Cancel'
            acceptButton='Delete'
            acceptDanger={true}
            error={deleteAddressError}
            onCancel={this.onCancelDeleteAddress.bind(this)}
            onAccept={this.onDeleteAddressConfirmed.bind(this)}
          />
        )}
        {showProviderTrainingWarning && (
          <ConfirmationDialog
            theme={theme}
            title='Delete training providers'
            question='You must delete all associated training before removing this provider'
            cancelButton='Close'
            isNotShowAcceptButton={true}
            onCancel={this.onCancelDeleteProvider.bind(this)}
            // onAccept={this.onDeleteProviderConfirmed.bind(this)}
          />
        )}
        {showCreateProviderDialog && (
          <CreateProviderDialog
            theme={theme}
            onClose={this.onCloseCreateTaskDialog.bind(this)}
            onSave={this.onSaveProvider.bind(this)}
            error={this.state.error}
            mode={this.state.providerDialogMode}
            providerData={this.state.editingProvider}
            isLoading={this.state.savingProvider}
          />
        )}
        {successMessage && (
          <Snackbar
            open={true}
            duration={3000}
            type='success'
            onClose={this.onSnackBarClose.bind(this)}
            message={successMessage}
          />
        )}
      </div>
    );
  }
}
